/* eslint-disable */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";

import { Route, Switch} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

//import logoBox from 'assets/img/logobox.png';
import logoBox from 'assets/img/logo_cantoli2.png';

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  function mapBreadscrumRoutes(array) {
    return   array.find(elem =>{

            if(props.location.pathname.indexOf(elem.path) > -1)
              return true;
       return false;
    })
  }

  // console.log(props);
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
        <Hidden smDown implementation="css">
         <p style={ { fontSize:'20px',fontWeight:'400' } }>Bienvenid@, { props.user && props.user.nombre}</p>
        </Hidden>
        <Hidden mdUp implementation="css">
         <p style={ { fontSize:'20px',fontWeight:'600' } } >CANTOLI</p>
        </Hidden>
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks  user={props.user} userAlertas={props.userAlertas} handleCloseSession={props.handleCloseSession} handleChangePass={props.handleChangePass}  />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
