import React, { Component } from "react";
import Input from 'components/Input/Input';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { StateUpload } from "../VariablesState";

import Database from "variables/Database.js";

import { toast } from 'react-toastify';

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import BackupIcon from '@material-ui/icons/Backup';

import Files from 'react-files'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class Upload extends Component {
  state = JSON.parse(JSON.stringify(StateUpload));


  handleSubmitUpload = (event) => {
    event.preventDefault();

    Database.post(`/insert-issue`, { nombre: this.state.uploadForm.nombre.value, telefono: this.state.uploadForm.telefono.value, mail: this.state.uploadForm.mail.value, puesto: this.state.uploadForm.puesto.value }, this)
      .then(res => {

        toast.success("La solicitud fue creada con exito!");
        this.setState({
          successSubmit: true,
          formIsValid: false,
        }, () => {
          // console.log("llamo a getReclutamientoAdmin");
          // this.props.getReclutamientoAdmin();
          // console.log("fin llamo a getReclutamientoAdmin");
        })
        this.resetNewForm();

      }, err => {
        toast.error(err.message);

      })
  }


  inputChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.uploadForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      uploadForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    })

  }

  resetNewForm = (all) => {
    let uploadFormAlt = { ...this.state.uploadForm };
    let successSubmit = this.state.successSubmit;
    for (let key in uploadFormAlt) {
      uploadFormAlt[key].value = ''
    }
    if (all)
      successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      formIsValid: false
    })
  }



  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }

  componentDidMount() {
    console.log("upload archivo");
    console.log(this.props.esValidacion);
    console.log(this.props.match.params.idreclutamiento);
    //this.getReclutamientoType();
  }



  onFilesChange = (files) => {
    console.log(files)
    this.setState({
      files: files
    })
    console.log(this.state.files);

    const formData = new FormData();
    formData.append('documento', files[0]);

    console.log(this.props.match.params);

    var id = this.props.match.params.idreclutamiento;//buscar id

    console.log(id);

    console.log(files[0].name);


    if (this.props.esValidacion) {
      Database.post('/insert-documento-validacion/' + id + "/" + files[0].name, formData, this, false, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          // setIsLoading(false);
          console.log("llamo a getReclutamientoAdmin");
          this.props.getReclutamientoAdmin();
          console.log("fin llamo a getReclutamientoAdmin");
          toast.success("El archivo de validacion de perfil " + files[0].name + " se ha subido con exito!");
          // callback.bind(this)(file_name);
          console.log(res);

        }, err => {
          //    setIsLoading(false);
          toast.error(err.message)

        })
    }
    else {
      Database.post('/insert-documento/' + id + "/" + files[0].name, formData, this, false, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          // setIsLoading(false);
          toast.success("El archivo " + files[0].name + " se ha subido con exito!");
          // callback.bind(this)(file_name);
          console.log(res);

        }, err => {
          //    setIsLoading(false);
          toast.error(err.message)

        })
    }


  }


  render() {

    const formElementsArray = [];
    for (let key in this.state.uploadForm) {
      formElementsArray.push({
        id: key,
        config: this.state.uploadForm[key]
      });
    }
    return (

      <form onSubmit={(event) => {
        this.handleSubmitUpload(event)

      }}>





        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nuevo Archivo para la búsqueda Nº {this.props.match.params.idreclutamiento}</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Subir nuevo archivo
            </p>
          </CardHeader>
          <CardBody>

            {/* <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"uploadarchivo-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputChangedHandler(event, formElement.id)}
                  />
              ))}
            </div> */}

            <div className="files">
              <Files
                className='files-dropzone'
                onChange={this.onFilesChange}
                //   onError={this.onFilesFotoError}
                accepts={['image/png', '.pdf', '.docx', '.doc', '.jpg']}
                multiple={false}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <Button style={{ marginTop: '25px', backgroundColor: '#8C8B20' }} color="primary" variant="contained" ><BackupIcon />&nbsp; Adjuntar archivo</Button>
              </Files>
            </div>

            <Button style={{ marginTop: '25px' }} color="info" onClick={() => this.props.history.goBack()} ><ArrowBack />Volver</Button>
            {/* <Button style={{ marginTop: '25px' }} color="primary" variant="contained" disabled={!this.state.formIsValid || this.state.disableAllButtons} type="submit" ><Save /> Guardar</Button> */}


          </CardBody>
        </Card>



      </ form>


    )
  }


};

export default withRouter(withStyles(styles)(Upload));
