import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from 'moment';

import { Route, Switch} from 'react-router-dom';
// core components
import MaterialTable from "material-table";
import DescriptionIcon from "@material-ui/icons/Description";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';
import CloudUpload from "@material-ui/icons/CloudUpload";
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import LoopIcon from '@material-ui/icons/Loop';
import lightGreen from '@material-ui/core/colors/lightGreen';

import NewReclutamientoCantoli from "./components/NewReclutamientoCantoli";

//import NewUser from "./components/NewReclutamiento";
//import EditReclutamiento from "./components/EditReclutamiento";
import ModalDelete from "./components/ModalDelete";
import { localization } from "variables/general.js";

import { toast } from 'react-toastify';

import ReclutamientoDetalle from "./ReclutamientoDetalle";
import { StateListReclutamiento, ColumnsListadoReclutamientoCantoli } from "./VariablesState";
import Upload from "./components/UploadArchivo";

import Mood from "@material-ui/icons/Mood";
import MoodBad from "@material-ui/icons/MoodBad";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class Reclutamiento extends Component {
  state = { ...StateListReclutamiento };


  componentDidMount() {
    this.getReclutamientoAdmin();
  }



  // handleToggle = value => {
  //   const currentIndex = this.state.checked.indexOf(value);
  //   const newChecked = [...this.state.checked];
  //   let deleteEnabled = false;
  //   let editEnabled = false;
  //   const botonesAcc = { ...this.state.botonesAcciones }
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   if (newChecked.length > 0) {
  //     deleteEnabled = true;
  //     if (newChecked.length === 1)
  //       editEnabled = true;
  //   }
  //   botonesAcc.editar.enabled = editEnabled;
  //   botonesAcc.delete.enabled = deleteEnabled;
  //   this.setState({
  //     botonesAcciones: botonesAcc,
  //     checked: newChecked
  //   })

  // };

  // menuHandleClose = (value) => {
  //   this.setState({
  //     menuContext: null
  //   })
  // }

  // menuHandleItemClick = (value) => {
  //   const newItem = { ...this.state.botonesAcciones[value] };
  //   let menuContext = { ...this.state.menuContext };
  //   if (newItem.enabled) {
  //     menuContext = null;

  //     if (value === 'nuevo') {
  //       this.setState({
  //         menuContext: menuContext
  //       })
  //       this.props.history.push(this.props.match.url + '/nuevoreclutamiento');
  //     }

  //     if (value === 'editar' && this.state.checked.length === 1) {
  //       this.setState({
  //         menuContext: menuContext
  //       })
  //       let idUser = this.state.checked[0].id;
  //       this.props.history.push(this.props.match.url + '/editarreclutamiento/' + idUser);
  //     }
  //   }
  // }

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    })
  }
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE RECLUTAMIENTO
  ////////////////////////
  ////////////////////////
  getReclutamientoAdmin = () => {
    this.setState({
      isLoading: true
    })

    console.log("getReclutamientoAdmin");

    Database.get('/list-reclutamiento',this,null,true)
      .then(res => {
        console.log(res.result);
     //   console.log(res.response.issues);
        let resultado = [...res.result];
        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            fecha_alta_mostrar: moment(elem.fecha_alta).isValid() ? moment(elem.fecha_alta).format("DD/MM/YYYY") : "",
           // asignado_mostrar: elem.fields.customfield_10033 != null ? elem.fields.customfield_10033.value.substring(0, elem.fields.customfield_10033.value.indexOf("|")) : ""
          };
        });

        this.setState({
          isLoading:false,
          reclutamiento: resultado,
          checked: [],
          menuContext: null,
          // botonesAcciones: {
          //   nuevo: {
          //     enabled: true,
          //     texto: 'Nuevo'
          //   },
          //   editar: {
          //     enabled: false,
          //     texto: 'Editar'
          //   },
          //   delete: {
          //     enabled: false,
          //     texto: 'Eliminar'
          //   }
          // }
        })


      },err =>{
        toast.error(err.message);

      })



  }



  // editSingleUser = value => {
  //   this.props.history.push(this.props.match.url + '/editarreclutamiento/' + value);
  // }

  handlePagination = offset => {
    this.setState({
      offset: offset
    })

  }

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    })
  }

  handleEditButton = rowData => {
   this.props.history.push(this.props.match.url + "/editar/" + rowData.id)
  }

  menuHandleClose = (value) => {
    this.setState({
      menuContext: null
    })
  }

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    })
  }
  
  handleDeleteReclutamiento = rowData => {
    console.log(rowData.id);
    Database.post('/delete-issue', { id: rowData.id },this).then(res => {
      //  let users = [...this.state.users]
      //   users = users.filter(elem => {
      //     if (elem.id == rowData.id)
      //       return false;

      //     return true;

      //   })

        this.setState({
       //   users: users,
          openDeleteDialog:false
        },()=>{
          toast.success("La solicitud de cancelación fue realizada con exito!. Recibirá respuesta a la brevedad.");
        })


    },err => {
      toast.error(err.message);
    })

  }

  sincronizar = () => {
    this.setState({
      isLoading: true
    })

    console.log("sincronizando");

    Database.get('/bash-info-rec',this,null,true)
      .then(res => {
        console.log("result");
        console.log(res);

        this.setState({
          isLoading:false
        })

      },err =>{
        toast.error(err.message);
      })
  }

  handleBajarValidacion = rowData => {
    console.log("bajando el informe del issue numero: " + rowData.id);

    let fileToDownload = "";

    console.log(rowData.archivo_validacion);


    if (rowData.archivo_validacion != null) {

      var link = document.createElement("a");
      link.setAttribute('target', '_blank');
      link.href = rowData.archivo_validacion; //'/issuefile/' + fileToDownload;

      console.log(link.href);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("termino");
    }
  }

  existeArchivoValidacionPerfil = rowData => {

    if (rowData.archivo_validacion != null)
      return true;

    return false;

  }

  handleAceptarButton = rowData => {
    // alert("Acepto!");
    this.setState({
      isLoading: true
    })

    Database.post('/aceptar-validacion-perfil', { id: rowData.id }, this).then(res => {
      this.setState({
        isLoading: false
      }, () => {
        toast.success("Se ha aceptado la validación de perfil.");
      })

      this.getReclutamientoAdmin();

    }, err => {
      toast.error(err.message);
    })
  }

  handleRechazarButton = rowData => {
    this.setState({
      isLoading: true
    })

    Database.post('/rechazar-validacion-perfil', { id: rowData.id }, this).then(res => {
      this.setState({
        isLoading: false
      }, () => {
        toast.success("Se ha rechazado la validación de perfil.");
      })

      this.getReclutamientoAdmin();

    }, err => {
      toast.error(err.message);
    })
  }
  

  render() {
    let style = {}
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: 'none' }
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite} >Búsquedas</h4>
              <p className={this.props.classes.cardCategoryWhite} >
                Listado de Búsquedas Cantoli
                      </p>
            </CardHeader>
            <CardBody>
              <Button style={{ marginTop: '25px' }} onClick={() => this.sincronizar()} color="primary"><LoopIcon /> Sincronizar con Jira</Button>
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListadoReclutamientoCantoli}
                data={this.state.reclutamiento}
                title=""
                localization={localization}

                actions={[
                  {
                    icon: 'search',
                    tooltip: 'Ver Detalle',
                    onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/reclutamientodetalle/' + rowData.id)
  
                  },
                //   {
                //   icon: DescriptionIcon,
                //   tooltip: 'Subir Archivo',
                //   onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/upload/' + rowData.id)
                // },
                { 
                  icon: CloudUpload,
                  tooltip: 'Subir Validacion de Perfil',
                  onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/upload/' + rowData.id)
                  
                },
                
                {
                  icon: 'edit',
                  tooltip: 'editar reclutamiento',
                  onClick: (event, rowData) => {this.handleEditButton(rowData)}

                },
                rowData => (
                  {
                    icon: DescriptionIcon,
                    tooltip: 'Ver Validación Perfil',
                    // onClick: (event, rowData) => this.handleBajarValidacion(rowData),
                    onClick: (event, rowData) => this.handleBajarValidacion(rowData),
                    disabled: this.existeArchivoValidacionPerfil(rowData) != true


                  }
                ),
                rowData => (
                  {
                    icon: Mood,
                    tooltip: 'Aceptar validación de Perfil',
                    onClick: (event, rowData) => { this.handleAceptarButton(rowData) },
                    disabled: this.existeArchivoValidacionPerfil(rowData) != true
                  }),
                rowData => (
                  {
                    icon: MoodBad,
                    tooltip: 'Rechazar validación de Perfil',
                    onClick: (event, rowData) => { this.handleRechazarButton(rowData) },
                    disabled: this.existeArchivoValidacionPerfil(rowData) != true
                  })
               ]}
                components={{
                  Container: props => (
                    <Paper elevation={0} {...props} />
                  )
                }}

                options={{
                  filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData:true,
                  exportFileName:"Reclutamiento " + moment().format("DD-MM-YYYY"),
                  exportDelimiter:";",
                  headerStyle: {
                    backgroundColor: "#414141",
                    color: '#FFF'
                  },
                }}
                />
            </CardBody>
          </Card>

          <Switch>
            <Route path={this.props.match.url + "/upload/:idreclutamiento"} render={() =>

              <Upload

                getReclutamientoAdmin={() => this.getReclutamientoAdmin()}
                esValidacion={true}

           //     handleListNewUser={(rowData) => this.handleListNewUser(rowData)}


                />}
              />

              <Route path={this.props.match.url + "/reclutamientodetalle/:idreclutamiento"} render={() =>

              <ReclutamientoDetalle

                getReclutamientoDetalleAdmin={() => this.getReclutamientoDetalleAdmin()}
              //     handleListNewUser={(rowData) => this.handleListNewUser(rowData)}


                />}
              />

            { <Route path={this.props.match.url + "/editar/:idReclutamiento"} render={() =>

              <NewReclutamientoCantoli
                  getReclutamientoAdmin = { () => this.getReclutamientoAdmin() }
                />}
              /> }

          </Switch>


        </GridItem>

        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={(rowData) => this.handleDeleteReclutamiento(rowData)}
          />

       
      </GridContainer>

    );
  }
}


export default withStyles(styles)(Reclutamiento);
