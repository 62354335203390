import React, { Component } from 'react';
import Input from 'components/Input/Input';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { withStyles } from '@material-ui/styles';

import Database from "variables/Database.js";
import { toast, ToastContainer } from 'react-toastify';

import MaterialTable from "material-table";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { localization } from "variables/general.js";

import { StateEditPsicotecnico, ColumnsListadoArchivos } from "../VariablesState";



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class EditPsicotecnico extends Component {
  state = JSON.parse(JSON.stringify(StateEditPsicotecnico));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    })
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    })
  };




  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }

  getNivel = () => {
    Database.get('/list-psicotecnicos-nivel', this)
      .then(res => {

        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function (entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        })


        let formulario = { ...this.state.editPsicotecnicoForm }
        formulario.nivel.elementConfig.options = [...a];
        this.setState({
          editPsicotecnicoForm: formulario
        })



      }, err => {
        toast.error(err.message);
      })
  }



  getPsicotecnicoEdit = (id) => {
    console.log(id);
    Database.get('/get-issue/' + id)
      .then(resultado => {
        console.log(resultado);
        if (resultado.response != null) {
          console.log(resultado.response);
          // this.setState({
          //   psicotecnicoEdit: resultado.response
          // })

          let editPsicotecnicoFormAlt = { ...this.state.editPsicotecnicoForm };
          console.log(editPsicotecnicoFormAlt);
          console.log(resultado.response.fields);
          console.log(resultado.response.fields.customfield_10036);

          editPsicotecnicoFormAlt.nombre.value = resultado.response.fields.customfield_10048;
          editPsicotecnicoFormAlt.mail.value = resultado.response.fields.customfield_10036;



          editPsicotecnicoFormAlt.telefono.value = resultado.response.fields.customfield_10037;
          editPsicotecnicoFormAlt.puesto.value = resultado.response.fields.customfield_10038;
          //  editPsicotecnicoFormAlt.comentario_cliente.value = resultado.response.mail2;
          //   console.log(resultado.response.fields.customfield_10064.value);
          editPsicotecnicoFormAlt.objetivo.value = resultado.response.fields.customfield_10063.value;
          editPsicotecnicoFormAlt.nivel.value = resultado.response.fields.customfield_10064.value;
          editPsicotecnicoFormAlt.feedback.value = resultado.response.fields.customfield_10070.value;


          console.log(editPsicotecnicoFormAlt);
          /*
                      for (let key in editPsicotecnicoFormAlt) {
                        editPsicotecnicoFormAlt[key].touched = true;
                        editPsicotecnicoFormAlt[key].valid = true;
                      }
          */
          this.setState({
            psicotecnicosArchivos: resultado.response.fields.attachment,
            editPsicotecnicoForm: editPsicotecnicoFormAlt
          })
          // this.getPsicotecnicoType("edit", editPsicotecnicoFormAlt);
        }
        else {
          this.setState({
            psicotecnicoEdit: null
          })
        }

      })
  }

  handleChangePass = (event) => {
    event.preventDefault();
    this.setState({
      openChangePass: false
    })

    Database.post(`/update-pass`, { id: this.props.match.params.idpsicotecnico, newpass: event.target.contrasenia.value })
      .then(res => {
        toast.success("El Psicotecnico se ha modificado con exito!");
      }, err => {
        toast.error(err.message);

      })
  }

  handleSubmitEditPsicotecnico = (event) => {

    event.preventDefault();

    Database.post(`/update-psicotecnico`, {
      id: this.props.match.params.idpsicotecnico, razon_social: this.state.editPsicotecnicoForm.razon_social.value, nombre_fantasia: this.state.editPsicotecnicoForm.nombre_fantasia.value,
      numero_psicotecnico: this.state.editPsicotecnicoForm.numero_psicotecnico.value, cuit: this.state.editPsicotecnicoForm.cuit.value, telefono: this.state.editPsicotecnicoForm.telefono.value,
      mail: this.state.editPsicotecnicoForm.mail.value, contacto: this.state.editPsicotecnicoForm.contacto.value,
      mail2: this.state.editPsicotecnicoForm.mail2.value, contacto2: this.state.editPsicotecnicoForm.contacto2.value, mail3: this.state.editPsicotecnicoForm.mail3.value, contacto3: this.state.editPsicotecnicoForm.contacto3.value
    })
      .then(res => {

        this.setState({
          successSubmitEdit: true,
          editFormIsValid: false,
          disableAllButtons: false
        }, () => {
          toast.success("El Psicotecnico se ha modificado con exito!");

          this.props.getPsicotecnicoAdmin();

        })

      }, err => {
        toast.error(err.message);

      })

  }


  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editPsicotecnicoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editPsicotecnicoForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    })

  }





  resetEditForm = () => {
    let editPsicotecnicoFormAlt = { ...this.state.editPsicotecnicoForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editPsicotecnicoFormAlt) {
      editPsicotecnicoFormAlt[key].value = ''
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    })


  }

  handleBajarInforme = rowData => {
    console.log("bajando el informe del issue numero: " + rowData.id);
    /*
        let fileToDownload = "";
    
        for (let archivo of rowData.fields.attachment) {
          console.log(archivo.filename);
          if (archivo.filename.toLowerCase().indexOf("informe") >= 0)
            if (archivo.filename.toLowerCase().indexOf("final") >= 0) {
              fileToDownload = archivo.id;
              //    return;
            }
        }
    */
    console.log(rowData.id);


    var link = document.createElement("a");
    /// link.download = name;
    link.href = '/issuefile/' + rowData.id;

    console.log(link.href);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;

    console.log("termino");


  }

  componentDidMount() {

    // this.getNivel();
    this.getPsicotecnicoEdit(this.props.match.params.idpsicotecnico);

    console.log(this.state.psicotecnicosArchivos);
  }

  render() {

    const formElementsArray = [];
    for (let key in this.state.editPsicotecnicoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editPsicotecnicoForm[key]
      });
    }

    return ([

      <form onSubmit={(event) => {

        this.handleSubmitEditPsicotecnico(event)

      }}>





        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Visualización archivos del psicotécnico de {this.state.editPsicotecnicoForm.nombre.value} </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Puesto al que aplica:  {this.state.editPsicotecnicoForm.puesto.value} 
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            {/* <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"editpsicotecnico-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputEditChangedHandler(event, formElement.id)}
                />
              ))}
            </div> */}

            <MaterialTable
              isLoading={this.state.isLoading}
              columns={ColumnsListadoArchivos}
              data={this.state.psicotecnicosArchivos}
              title=""
              localization={localization}

              actions={[
                {
                  icon: CloudDownloadIcon,
                  tooltip: 'Descargar',
                  onClick: (event, rowData) => this.handleBajarInforme(rowData)
                }

              ]}
              // components={{
              //   Container: props => (
              //     <Paper elevation={0} {...props} />
              //   )
              // }}

              options={{
                filtering: true,
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                exportFileName: "Psicotecnicos_Archivos " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                headerStyle: {
                  backgroundColor: "#414141",
                  color: '#FFF'
                },
              }}
            />

            <Button style={{ marginTop: '25px' }} color="info" variant="outlined" onClick={() => this.props.history.goBack()} ><ArrowBack />Volver</Button>
            {/* <Button style={{ marginTop: '25px' }} color="primary" variant="contained" disabled={!this.state.editFormIsValid || this.state.disableAllButtons} type="submit" ><Save /> Guardar</Button> */}


          </CardBody>
        </Card>


      </ form>



    ])
  }

};

export default withRouter(withStyles(styles)(EditPsicotecnico));
