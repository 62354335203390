/* eslint-disable */
import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import LocalShipping from "@material-ui/icons/LocalShipping";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Alarm from "@material-ui/icons/Alarm";
import Check from "@material-ui/icons/Check";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Database from "variables/Database.js";
import { toast } from 'react-toastify';
import { Route, Switch, Link } from 'react-router-dom';

import { bugs, website, server } from "variables/general.js";
import "./chartlistcustom.css";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  entradasPorDia,
  salidasPorDia,
  movimientosPorDia,
  issuesPorColumna,
  issuesPorColumnaRec
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();


  const [count, setCount] = useState(0);
  const [ocupacion, setOcupacion] = useState(0);
  const [clientes, setClientes] = useState(0);



  var dia = new Date();
  //const [diasLabelsEntradas, setDiasLabelsEntradas] = useState([]);
  //const [diasLabelsMovimientos, setDiasLabelsMovimientos] = useState([sumarDias(new Date(), -6).getDate(), sumarDias(new Date(), -5).getDate(), sumarDias(new Date(), -4).getDate(), sumarDias(new Date(), -3).getDate(), sumarDias(new Date(), -2).getDate(), sumarDias(new Date(), -1).getDate(), dia.getDate()]);
  //const [diasLabelsSalidas, setDiasLabelsSalidas] = useState([sumarDias(new Date(), -6).getDate(), sumarDias(new Date(), -5).getDate(), sumarDias(new Date(), -4).getDate(), sumarDias(new Date(), -3).getDate(), sumarDias(new Date(), -2).getDate(), sumarDias(new Date(), -1).getDate(), dia.getDate()]);


  //const [entradasDiarias, setEntradasDiarias] = useState([[]]);
  //const [movimientosDiarios, setMovimientosDiarios] = useState([[10, 0, 0, 0, 0, 0, 10]]);
  //const [salidasDiarias, setSalidasDiarias] = useState([[0, 0, 10, 10, 0, 0, 10]]);



  const [entradasDiariasData, setEntradasDiariasData] = useState({
    labels: [],
    series: [[]]
  });


  const [movimientosDiariosData, setMovimientosDiariosData] = useState({
    labels: [],
    series: [[]]
  });

  const [salidasDiariasData, setSalidasDiariasData] = useState({
    labels: [],
    series: [[]]
  });

  const [issuesPorColumnaData, setIssuesPorColumnaData] = useState({
    options: {},
    series: [{}],
    labels: []
  });

  const [issuesPorColumnaDataRec, setIssuesPorColumnaDataRec] = useState({
    options: {},
    seriesRec: [{}],
    labelsRec: []
  });


  const cantidad = 5;  // la cantidad de elementos a mostrar en los gráficos




  /*******************FUNCIONES AUXILIARES**********************/

  /* Función que suma o resta días a una fecha, si el parámetro
   días es negativo restará los días*/
  function sumarDias(fecha, dias) {
    //console.log(fecha);
    fecha.setDate(fecha.getDate() + dias);
    // console.log(fecha);
    return fecha;
  }


  /** a partir de una fecha devuelve dd/mm **/
  function diames(fecha) {
    let f = new Date(fecha);
    let h = new Date(f.getUTCFullYear(), f.getUTCMonth(), f.getUTCDate());
    let mes = h.getUTCMonth() + 1;
    return h.getUTCDate() + "/" + mes;

  }



  function cargarSalidasPorDia(salidas) {
    let salidasCantidad = [];
    let salidasValores = [];

    console.log(salidas);
    console.log(salidas.length);


    //invierto el array para que ordene por fecha descendente
    const ultimasSalidas = salidas.reverse();

    //si tengo mas elementos que el total a mostrar entonces elimino
    if (ultimasSalidas.length > cantidad) {
      ultimasSalidas.splice(cantidad, ultimasSalidas.length - cantidad);
    }

    ultimasSalidas.forEach(element => {
      salidasCantidad.push(diames(Object.values(element)[0]));
      salidasValores.push(Object.values(element)[1]);
    });

    setSalidasDiariasData({
      labels: salidasCantidad.reverse(),
      series: [salidasValores.reverse()]
    })

    return 1;

  }

  /*******************FUNCIONES AUXILIARES**********************/

  useEffect(() => {



    Database.get('/list-my-statused', this, null, true)
      .then(res => {

        console.log(res);
        // count = res[0];
        console.log(res.status);

        let series = [];
        let labels = []

        for (let i in res.status) {
          if (res.status[i].cantidad > 0) //los que vengan con cero no los muestro en el gráfico
          {
            series.push({ value: res.status[i].cantidad, name: res.status[i].name });
            labels.push(res.status[i].cantidad + " - " + res.status[i].name);
          }
        }

        console.log(labels);
        console.log(series);

        setIssuesPorColumnaData({
          options: {
            width: "400px",
            height: "400px",
            donut: false
          },
          series: series,
          labels: labels
        })


        console.log(issuesPorColumnaData);



      }, err => {
        toast.error(err.message);

      });

      // Database.get('/list-my-statused-rec/1', this, null, true)
      // .then(res => {

      //   console.log(res);
      //   // count = res[0];
      //   console.log(res.status);

      //   let seriesRec = [];
      //   let labelsRec = []

      //   for (let i in res.status) {
      //     if (res.status[i].cantidad > 0) //los que vengan con cero no los muestro en el gráfico
      //     {
      //       seriesRec.push({ value: res.status[i].cantidad, name: res.status[i].name });
      //       labelsRec.push(res.status[i].cantidad + " - " + res.status[i].name);
      //     }
      //   }

      //   console.log(labelsRec);
      //   console.log(seriesRec);
        

      //   setIssuesPorColumnaDataRec({
      //     options: {
      //       width: "400px",
      //       height: "400px",
      //       donut: false
      //     },
      //     seriesRec: seriesRec,
      //     labelsRec: labelsRec
      //   })


      //   console.log(issuesPorColumnaDataRec);



      // }, err => {
      //   toast.error(err.message);

      // })
  }, []);


  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <a href="psicotecnicoslistado">
            <Card chart>
              <CardHeader>
                <ChartistGraph

                  // data={issuesPorColumna.data}
                  data={issuesPorColumnaData}
                  type="Pie"
                  options={issuesPorColumna.options}
                  responsiveOptions={[
                    ['screen and (min-width: 640px)', {
                      chartPadding: 20,
                      labelOffset: 60,
                      //  labelDirection: 'explode',
                      labelInterpolationFnc: function (value) {
                        return value;
                      }
                    }],
                    ['screen and (min-width: 1024px)', {
                      chartPadding: 20,
                      labelOffset: 20
                    }]
                  ]}
                // listener={entradasPorDia.animation}
                /> 
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Psicotécnicos</h4>
                <p className={classes.cardCategory}>
                  Cantidad de psicotécnicos por etapa
                </p>
              </CardBody>
              <CardFooter chart>
                {/* <div className={classes.stats}>
                <AccessTime /> actualizado recientemente
              </div> */}
              </CardFooter>
            </Card>
          </a>
        </GridItem>

        {/* <GridItem xs={12} sm={12} md={6}>
          <a href="psicotecnicoslistado">
            <Card chart>
              <CardHeader>
                <ChartistGraph

                  // data={issuesPorColumna.data}
                  data={issuesPorColumnaDataRec}
                  type="Pie"
                  donut= "true"
                  options={issuesPorColumnaDataRec.options}
                  responsiveOptions={[
                    ['screen and (min-width: 640px)', {
                      chartPadding: 20,
                      labelOffset: 60,
                      //  labelDirection: 'explode',
                      labelInterpolationFnc: function (value) {
                        return value;
                      }
                    }],
                    ['screen and (min-width: 1024px)', {
                      chartPadding: 20,
                      labelOffset: 20
                    }]
                  ]}
                // listener={entradasPorDia.animation}
                /> 
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Reclutamiento</h4>
                <p className={classes.cardCategory}>
                  Cantidad de candidatos por etapa
                </p>
              </CardBody>
              <CardFooter chart>
              </CardFooter>
            </Card>
          </a>
        </GridItem> */}

      </GridContainer>

      {/*



new Chartist.Pie('.ct-chart', {
  series: [20, 10, 30, 40]
}, {
  donut: true,
  donutWidth: 60,
  donutSolid: true,
  startAngle: 270,
  total: 200,
  showLabel: true
});



      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Espacio Utilizado</p>
              <h3 className={classes.cardTitle}>
                 {ocupacion}% 
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LocalShipping />
              </CardIcon>
              <p className={classes.cardCategory}>Camiones en carga</p>
              <h3 className={classes.cardTitle}>Desconocido</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Tareas Pendientes</p>
              <h3 className={classes.cardTitle}>Desconocido</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Clientes en Almacén</p>
              <h3 className={classes.cardTitle}>{clientes}</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={entradasDiariasData}
                type="Line"
                options={entradasPorDia.options}
                listener={entradasPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Entradas por día</h4>
              <p className={classes.cardCategory}>
                Últimas entradas realizadas
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={movimientosDiariosData}
                type="Line"
                options={movimientosPorDia.options}
                listener={movimientosPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Movimientos por día</h4>
              <p className={classes.cardCategory}>Últimos movimientos realizados</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={salidasDiariasData}
                type="Line"
                options={salidasPorDia.options}
                listener={salidasPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Salidas por día</h4>
              <p className={classes.cardCategory}>Últimas salidas realizadas</p>
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tareas:"
            headerColor="primary"
            tabs={[
              // {
              //   tabName: "Picking",
              //   tabIcon: BugReport,
              //   tabContent: (
              //     <Tasks
              //       checkedIndexes={[0, 3]}
              //       tasksIndexes={[0, 1, 2, 3]}
              //       tasks={bugs}
              //     />
              //   )
              // },
              {
                tabName: "Autorizaciones",
                tabIcon: Check,
                tabContent: (
                  <Tasks
                    checkedIndexes={[]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Alertas",
                tabIcon: Alarm,
                tabContent: (
                  <Tasks
                    checkedIndexes={[]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Principales Clientes</h4>
              <p className={classes.cardCategoryWhite}>
                Clientes con mayor porcentaje de almacén ocupado
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Número", "Razón social", "Espacio", "Pais"]}
                tableData={[
                  ["132", "Coca Cola", "15.05%", "Paraguay"],
                  ["27", "Samsung", "12.23%", "Paraguay"],
                  ["93", "GE", "7.54%", "Argentina"],
                  ["41", "Mercedez Benz", "6.85%", "Paraguay"]
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
 */}

    </div>
  );
}
