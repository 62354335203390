export const StateListReclutamiento = {
    reclutamiento: [],
    offset:0,
    checked: [],
    menuContext: null,
    botonesAcciones: {
        nuevo: {

            enabled: true,
            texto: 'Nuevo'
        },
        editar: {

            enabled: false,
            texto: 'Editar'
        },
        delete: {

            enabled: false,
            texto: 'Eliminar'
        }
    },
    modalOpen: false,
    openDeleteDialog:false,
    deleteRowData:null,
    isLoading:false
}

export const StateListReclutamientoDetalle = {
    reclutamientoDetalle: [],
    reclutamientoPerfil: "Posición",
    reclutamientoDescripcion: "Descripcion",
    offset:0,
    checked: [],
    serie:[],
    labels:[],
    menuContext: null,
    // botonesAcciones: {
    //     nuevo: {

    //         enabled: true,
    //         texto: 'Nuevo'
    //     },
    //     editar: {

    //         enabled: false,
    //         texto: 'Editar'
    //     },
    //     delete: {

    //         enabled: false,
    //         texto: 'Eliminar'
    //     }
    // },
    modalOpen: false,
    openDeleteDialog:false,
    deleteRowData:null,
    isLoading:false
}


export const StateListReclutamientoCandidatos = {
    reclutamientoCandidatos: [],
    reclutamientoEtapa: "Etapa",
    reclutamientoDescripcion: "Descripcion",
    offset:0,
    checked: [],
    menuContext: null,
    // botonesAcciones: {
    //     nuevo: {

    //         enabled: true,
    //         texto: 'Nuevo'
    //     },
    //     editar: {

    //         enabled: false,
    //         texto: 'Editar'
    //     },
    //     delete: {

    //         enabled: false,
    //         texto: 'Eliminar'
    //     }
    // },
    modalOpen: false,
    openDeleteDialog:false,
    deleteRowData:null,
    isLoading:false
}


/*

export const StateEditReclutamiento = {

    editReclutamientoForm: {
        razon_social: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Razon Social',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        nombre_fantasia: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre Fantasia',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        ruc: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'CUIT',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        numero_reclutamiento: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Numero Reclutamiento',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        telefono: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

    },
    reclutamientoEdit: null,
    editFormIsValid: false,
    successSubmitEdit: null,
    disableAllButtons:false,
    openChangePass: false

}

*/

export const StateNewReclutamiento = {

    newReclutamientoForm: {
        perfil: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Posición',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        observaciones: {
            elementType: 'textarea',
            elementConfig: {
                type: 'text',
                label: 'Observaciones',
                fullWidth: true,
                rows:4
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }  ,
        referente_cliente: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Referente de la Búsqueda',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }  ,         

        
    },

    formIsValid: false,
    successSubmit: null,
    disableAllButtons:false,
    archivoSubido: false,
    idReclutamiento: -1
}

export const StateNewReclutamientoCantoli = {

    newReclutamientoCantoliForm: {
        cliente: {
            elementType: 'select',
            elementConfig: {
                label:'Cliente',
                 options: [
    
                ],
                fullWidth: true
            },
            value: '',
            validation: {
                required:true
            },
    
            valid: false,
            touched: false
        },
        perfil: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Posición',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
       
        observaciones: {
            elementType: 'textarea',
            elementConfig: {
                type: 'text',
                label: 'Observaciones',
                fullWidth: true,
                rows:4
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        referente_rrhh: {
            elementType: 'select',
            elementConfig: {
                label:'Referente Cantoli',
                 options: [
    
                ],
                fullWidth: true
            },
            value: '',
            validation: {
                required:true
            },
    
            valid: false,
            touched: false
        },    
        referente_rrhh_2: {
            elementType: 'select',
            elementConfig: {
                label:'Referente Cantoli 2',
                 options: [
    
                ],
                fullWidth: true
            },
            value: '',
            validation: {
                required:false
            },
    
            valid: true,
            touched: true
        },    
        referente_cliente: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Referente del lado del Cliente',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        } ,       
        estado: {
            elementType: 'select',
            elementConfig: {
                label:'Estado',
                 options: [
                    { displayValue:'En Proceso', value:'1' },
                    { displayValue:'Suspendida', value:'2' },
                    { displayValue:'Finalizada', value:'3' }
                ],
                fullWidth: true
            },
            value: '1',
            validation: {
                required:false
            },
    
            valid: true,
            touched: true
        },    
                
    },
    formIsValid: false,
    successSubmit: null,
    disableAllButtons:false,
    archivoSubido: false,
    adjuntoDesactivado: true,
    idReclutamiento: -1,
    isEdit: false,
    rowData:null,
    openDeleteDialog:false
}


export const StateUpload = {

    newUploadForm: {
        nombre: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre y Apellido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    },

    formIsValid: false,
    successSubmit: null,
    disableAllButtons:false
}


export const ColumnsListado = [
{ title: "Código Busqueda", field: "id" },
//{ title: "Key", field: "key" },
{ title: "Posición", field: "perfil" },
{ title: "Fecha de Inicio", field: "fecha_alta_mostrar" },
{ title: "Referente", field: "referente_cliente" },
{ title: "Validación Perfil", field: "perfil_validado_descripcion" },

// { title: "Observaciones", field: "observaciones" }
// { title: "Empresa", field: "fields.customfield_10032" },
// { title: "Asignado a", field: "asignado_mostrar" },
// //{ title: "Contacto Empresa", field: "fields.customfield_10034" },
// // { title: "Mail", field: "fields.customfield_10036" },
// // { title: "Telefono", field: "fields.customfield_10037" },
// { title: "Puesto al que aplica", field: "fields.customfield_10038" },
// { title: "Objetivo", field: "fields.customfield_10063.value" },
// { title: "Nivel", field: "fields.customfield_10064.value" },

];

export const ColumnsListadoReclutamientoCantoli = [
    { title: "Código Busqueda", field: "id" },
    { title: "Cliente", field: "razon_social" },
    { title: "Posición", field: "perfil" },
    { title: "Fecha de Inicio", field: "fecha_alta_mostrar" },
    { title: "Referente", field: "referente_cliente" },
    { title: "Estado", field: "estado_descripcion" },
    { title: "Validación Perfil", field: "perfil_validado_descripcion" },

    ];

export const ColumnsListadoDetalle = [
 //   { title: "ID", field: "id" },
 { title: "Etapa", field: "name" },
 //   { title: "Perfil", field: "perfil" },
 //   { title: "Descripción", field: "descripcion_perfil" }
     { title: "Cantidad", field: "cantidad" },
    // { title: "Empresa", field: "fields.customfield_10032" },
    // { title: "Asignado a", field: "asignado_mostrar" },
    // //{ title: "Contacto Empresa", field: "fields.customfield_10034" },
    // // { title: "Mail", field: "fields.customfield_10036" },
    // // { title: "Telefono", field: "fields.customfield_10037" },
    // { title: "Puesto al que aplica", field: "fields.customfield_10038" },
    // { title: "Objetivo", field: "fields.customfield_10063.value" },
    // { title: "Nivel", field: "fields.customfield_10064.value" },
    
    ];

    export const ColumnsListadoDetalleCandidatos = [
        { title: "Key", field: "key" },
       // { title: "Etapa", field: "name" },
        { title: "Nombre", field: "fields.customfield_10048" },
        { title: "Formacion", field: "fields.customfield_10049" },
          //  { title: "Cantidad", field: "cantidad" },
        { title: "Empresa Actual", field: "fields.customfield_10051" },
        { title: "Fecha Entrevista ", field: "fecha_linea_mostrar" },
        { title: "Fecha Presentacion", field: "fecha_presentacion_mostrar" },
      //   { title: "Feedback Linea", field: "fields.customfield_10082.value" },
           // //{ title: "Contacto Empresa", field: "fields.customfield_10034" },
           // // { title: "Mail", field: "fields.customfield_10036" },
           // // { title: "Telefono", field: "fields.customfield_10037" },
           // { title: "Puesto al que aplica", field: "fields.customfield_10038" },
           // { title: "Objetivo", field: "fields.customfield_10063.value" },
           // { title: "Nivel", field: "fields.customfield_10064.value" },
           
           ];