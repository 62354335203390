export const StateListPsicotecnicos = {
    psicotecnicos: [],
    offset: 0,
    checked: [],
    menuContext: null,
    botonesAcciones: {
        nuevo: {

            enabled: true,
            texto: 'Nuevo'
        },
        editar: {

            enabled: false,
            texto: 'Editar'
        },
        delete: {

            enabled: false,
            texto: 'Eliminar'
        }
    },
    modalOpen: false,
    openDeleteDialog: false,
    deleteRowData: null,
    isLoading: false




}


/*

export const StateEditPsicotecnico = {

    editPsicotecnicoForm: {
        razon_social: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Razon Social',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        nombre_fantasia: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre Fantasia',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        ruc: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'CUIT',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        numero_psicotecnico: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Numero Psicotecnico',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        telefono: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

    },
    psicotecnicoEdit: null,
    editFormIsValid: false,
    successSubmitEdit: null,
    disableAllButtons:false,
    openChangePass: false

}

*/

export const StateNewPsicotecnico = {

    newPsicotecnicoForm: {
        titulo2: {
            type: 'titulo',
            text: 'Información Cliente'
        },

        pedido_a_nombre_de: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Responsable del pedido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        facturar_a: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Facturar a (indicar razón social)',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        titulo3: {
            type: 'titulo',
            text: '* Enviar Informe a:'
        },
        contacto1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Nombre y Apellido Principal',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Mail Principal',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true,
                mail: true
            },
            valid: false,
            touched: false
        },
        contacto2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre y Apellido Secundario (opcional)',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail Secundario (opcional)',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false,
                mail: true
            },
            valid: true,
            touched: true
        },
        contacto3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre y Apellido Secundario 2 (opcional)',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail Secundario 2 (opcional)',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false,
                mail: true
            },
            valid: true,
            touched: true
        },



        titulo1: {
            type: 'titulo',
            text: 'Información Candidato'
        },
        nombre: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Nombre y Apellido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Mail',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true,
                mail: true
            },
            valid: false,
            touched: false
        },
        telefono: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        puesto: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: '* Puesto al que aplica',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        objetivo: {
            elementType: 'select',
            elementConfig: {
                label: '* Objetivo del Psicotécnico',
                options: [

                ],
                autoWidth: true
            },
            value: '',
            validation: {
                required: true
            },

            valid: false,
            touched: false
        },
        nivel: {
            elementType: 'select',
            elementConfig: {
                label: '* Nivel del Psicotécnico',
                options: [

                ],
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },

            valid: false,
            touched: false
        },
        feedback: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: 'Feedback Requerido',
                fullWidth: true
            },
            value: 0,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },



        comentario_cliente: {
            elementType: 'textarea',
            elementConfig: {
                type: 'text',
                label: 'Observaciones',
                fullWidth: true,
                rows: 4
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }
    },

    modalOpen: false,
    openSubirArchivoDialog: false,
    subirarchivoRowData: null,
    isLoading: false,

    formIsValid: false,
    successSubmit: null,
    disableAllButtons: false,
    activeStep: 0

}


export const StateEditPsicotecnico = {

    editPsicotecnicoForm: {
        nombre: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Nombre y Apellido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'email',
                label: 'Mail',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        telefono: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        puesto: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Puesto al que aplica',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        objetivo: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Objetivo del Psicotécnico',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        // objetivo: {
        //     elementType: 'select',
        //     elementConfig: {
        //         label: 'Objetivo del Psicotécnico',
        //         options: [

        //         ],
        //         fullWidth: true
        //     },
        //     value: '',
        //     validation: {
        //         required: true
        //     },

        //     valid: false,
        //     touched: false
        // },
        nivel: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Nivel del Psicotécnico',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        // nivel: {
        //     elementType: 'select',
        //     elementConfig: {
        //         label: 'Nivel del Psicotécnico',
        //         options: [

        //         ],
        //         fullWidth: true
        //     },
        //     value: '',
        //     validation: {
        //         required: true
        //     },

        //     valid: false,
        //     touched: false
        // },
        feedback: {
            elementType: 'input',
            elementConfig: {
                disabled: true,
                type: 'text',
                label: 'Feedback Requerido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        // feedback: {
        //     elementType: 'checkbox',
        //     elementConfig: {
        //       type: 'checkbox',
        //       label: 'Feedback Requerido',
        //       fullWidth: true
        //     },
        //     value: 0,
        //     validation: {
        //       required: false
        //     },
        //     valid: true,
        //     touched: false
        //   },
        // comentario_cliente: {
        //     elementType: 'textarea',
        //     elementConfig: {
        //         type: 'text',
        //         label: 'Información Adicional',
        //         fullWidth: true,
        //         rows:4
        //     },
        //     value: '',
        //     validation: {
        //         required: false
        //     },
        //     valid: true,
        //     touched: true
        // }            
    },
    psicotecnicosArchivos: [],
    modalOpen: false,
    openSubirArchivoDialog: false,
    subirarchivoRowData: null,
    isLoading: false,

    editFormIsValid: false,
    successSubmitEdit: null,
    disableAllButtons: false
}



export const StateUpload = {

    newUploadForm: {
        nombre: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre y Apellido',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    },

    formIsValid: false,
    successSubmit: null,
    disableAllButtons: false
}


export const ColumnsListado = [
    // { title: "ID", field: "id" },
    { title: "Key", field: "key" },
    { title: "Nombre", field: "fields.customfield_10048" },
    { title: "Etapa", field: "fields.status.name" },
    { title: "Empresa", field: "fields.customfield_10032" },
    { title: "Asignado a", field: "asignado_mostrar" },
    //{ title: "Contacto Empresa", field: "fields.customfield_10034" },
    // { title: "Mail", field: "fields.customfield_10036" },
    // { title: "Telefono", field: "fields.customfield_10037" },
    { title: "Puesto al que aplica", field: "fields.customfield_10038" },
    { title: "Objetivo", field: "fields.customfield_10063.value" },
    // { title: "Proyecto", field: "proyecto_mostrar" },
    { title: "Nivel", field: "fields.customfield_10064.value" },
    { title: "Feedback Requerido", field: "fields.customfield_10070.value" }

];

export const ColumnsListadoArchivos = [
    // { title: "ID", field: "id" },
    { title: "Key", field: "id" },
    { title: "Nombre", field: "filename" }
];

