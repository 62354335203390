import React, { Component } from "react";
import Input from 'components/Input/Input';
import InputNew from 'components/Input/InputNew';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { StateNewPsicotecnico } from "../VariablesState";

import Database from "variables/Database.js";

import { toast } from 'react-toastify';

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import ModalSubirArchivo from "./ModalSubirArchivo";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

///STEPPER///
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const steps = ['Iniciar pedido', 'Datos del candidato', 'Enviar solicitud'];
///STEPPER///


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class NewPsicotecnico extends Component {
  state = JSON.parse(JSON.stringify(StateNewPsicotecnico));


  ///STEPPER///
  handleNext = () => {
    let newStep = this.state.activeStep;
    newStep += 1;

    console.log("next " + this.state.activeStep + " - " + newStep);


    let camposAValidar = [];
    switch (this.state.activeStep) {
      case 0:
        if (this.verificarValidacionesPaso0())
          this.setState({
            activeStep: newStep
          })
        else
          toast.error("Debe completar correctamente los campos obligatorios para avanzar.");
        break;
        case 1:
          if (this.verificarValidacionesPaso1())
            this.setState({
              activeStep: newStep
            })
          else
            toast.error("Debe completar correctamente los campos obligatorios para avanzar.");
          break;
          case 2:
            if (this.verificarValidacionesPaso2())
              this.setState({
                activeStep: newStep
              })
            else
              toast.error("Debe completar correctamente los campos obligatorios para avanzar.");
            break;
      default:
        toast.error("Se produjo un error, por favor reintentar.");
        break;
    }
  };

  handleBack = () => {
    let newStep = this.state.activeStep;
    newStep -= 1;

    console.log("back " + + this.state.activeStep + " - " + newStep);

    this.setState({
      activeStep: newStep
    })


  };

  handleReset = () => {
    let newStep = 0;

    this.setState({
      activeStep: newStep
    })
  };


  verificarValidacionesPaso0 = () => {
    if((this.state.newPsicotecnicoForm.pedido_a_nombre_de.valid) && (this.state.newPsicotecnicoForm.facturar_a.valid) &&(this.state.newPsicotecnicoForm.contacto1.valid) &&(this.state.newPsicotecnicoForm.mail1.valid))
        return true;
    
    return false;
  }

  verificarValidacionesPaso1 = () => {
    if((this.state.newPsicotecnicoForm.nombre.valid) && (this.state.newPsicotecnicoForm.mail.valid) &&(this.state.newPsicotecnicoForm.telefono.valid) &&(this.state.newPsicotecnicoForm.puesto.valid) &&(this.state.newPsicotecnicoForm.objetivo.valid))
        return true;
    
    return false;
  }

  verificarValidacionesPaso2 = () => {
    if((this.state.newPsicotecnicoForm.nivel.valid))
        return true;
    
    return false;
  }


  // handleSubmitNewSolicitudPsicotecnico = () => {
  //   //event.preventDefault();

  //   console.log(this.state.newPsicotecnicoForm);
  // }


  getStepContent = (formElementsArray, activeStep) => {

    switch (activeStep) {
      case 0: return (
        <Box m={6} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3><b>Nueva Solicitud</b></h3>
            </Grid>
            <Grid item xs={12}>
              <h6>Los campos marcados con * son obligatorios</h6>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[1].id}
                elementType={formElementsArray[1].config.elementType}
                elementConfig={formElementsArray[1].config.elementConfig}
                value={formElementsArray[1].config.value}
                textValid={formElementsArray[1].config.textValid}
                invalid={!formElementsArray[1].config.valid}
                shouldValidate={formElementsArray[1].config.validation}
                touched={formElementsArray[1].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[1].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[2].id}
                elementType={formElementsArray[2].config.elementType}
                elementConfig={formElementsArray[2].config.elementConfig}
                value={formElementsArray[2].config.value}
                textValid={formElementsArray[2].config.textValid}
                invalid={!formElementsArray[2].config.valid}
                shouldValidate={formElementsArray[2].config.validation}
                touched={formElementsArray[2].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[2].id, newValue)}
              />
            </Grid>
            <Grid item xs={12}>
              <h5><b>Enviar informe a</b></h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[4].id}
                elementType={formElementsArray[4].config.elementType}
                elementConfig={formElementsArray[4].config.elementConfig}
                value={formElementsArray[4].config.value}
                textValid={formElementsArray[4].config.textValid}
                invalid={!formElementsArray[4].config.valid}
                shouldValidate={formElementsArray[4].config.validation}
                touched={formElementsArray[4].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[4].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[5].id}
                elementType={formElementsArray[5].config.elementType}
                elementConfig={formElementsArray[5].config.elementConfig}
                value={formElementsArray[5].config.value}
                textValid={formElementsArray[5].config.textValid}
                invalid={!formElementsArray[5].config.valid}
                shouldValidate={formElementsArray[5].config.validation}
                touched={formElementsArray[5].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[5].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[6].id}
                elementType={formElementsArray[6].config.elementType}
                elementConfig={formElementsArray[6].config.elementConfig}
                value={formElementsArray[6].config.value}
                textValid={formElementsArray[6].config.textValid}
                invalid={!formElementsArray[6].config.valid}
                shouldValidate={formElementsArray[6].config.validation}
                touched={formElementsArray[6].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[6].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[7].id}
                elementType={formElementsArray[7].config.elementType}
                elementConfig={formElementsArray[7].config.elementConfig}
                value={formElementsArray[7].config.value}
                textValid={formElementsArray[7].config.textValid}
                invalid={!formElementsArray[7].config.valid}
                shouldValidate={formElementsArray[7].config.validation}
                touched={formElementsArray[7].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[7].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[8].id}
                elementType={formElementsArray[8].config.elementType}
                elementConfig={formElementsArray[8].config.elementConfig}
                value={formElementsArray[8].config.value}
                textValid={formElementsArray[8].config.textValid}
                invalid={!formElementsArray[8].config.valid}
                shouldValidate={formElementsArray[8].config.validation}
                touched={formElementsArray[8].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[8].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[9].id}
                elementType={formElementsArray[9].config.elementType}
                elementConfig={formElementsArray[9].config.elementConfig}
                value={formElementsArray[9].config.value}
                textValid={formElementsArray[9].config.textValid}
                invalid={!formElementsArray[9].config.valid}
                shouldValidate={formElementsArray[9].config.validation}
                touched={formElementsArray[9].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[9].id, newValue)}
              />
            </Grid>
          </Grid>
        </Box>

      );
        break;
      case 1: return (
        <Box m={6} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3><b>Nueva Solicitud</b></h3>
            </Grid>
            <Grid item xs={12}>
              <h5><b>Información del candidato</b></h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[11].id}
                elementType={formElementsArray[11].config.elementType}
                elementConfig={formElementsArray[11].config.elementConfig}
                value={formElementsArray[11].config.value}
                textValid={formElementsArray[11].config.textValid}
                invalid={!formElementsArray[11].config.valid}
                shouldValidate={formElementsArray[11].config.validation}
                touched={formElementsArray[11].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[11].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[12].id}
                elementType={formElementsArray[12].config.elementType}
                elementConfig={formElementsArray[12].config.elementConfig}
                value={formElementsArray[12].config.value}
                textValid={formElementsArray[12].config.textValid}
                invalid={!formElementsArray[12].config.valid}
                shouldValidate={formElementsArray[12].config.validation}
                touched={formElementsArray[12].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[12].id, newValue)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[13].id}
                elementType={formElementsArray[13].config.elementType}
                elementConfig={formElementsArray[13].config.elementConfig}
                value={formElementsArray[13].config.value}
                textValid={formElementsArray[13].config.textValid}
                invalid={!formElementsArray[13].config.valid}
                shouldValidate={formElementsArray[13].config.validation}
                touched={formElementsArray[13].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[13].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[14].id}
                elementType={formElementsArray[14].config.elementType}
                elementConfig={formElementsArray[14].config.elementConfig}
                value={formElementsArray[14].config.value}
                textValid={formElementsArray[14].config.textValid}
                invalid={!formElementsArray[14].config.valid}
                shouldValidate={formElementsArray[14].config.validation}
                touched={formElementsArray[14].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[14].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[15].id}
                elementType={formElementsArray[15].config.elementType}
                elementConfig={formElementsArray[15].config.elementConfig}
                value={formElementsArray[15].config.value}
                textValid={formElementsArray[15].config.textValid}
                invalid={!formElementsArray[15].config.valid}
                shouldValidate={formElementsArray[15].config.validation}
                touched={formElementsArray[15].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[15].id, newValue)}
              />
            </Grid>
          </Grid>
        </Box>
      );
        break;
      case 2: return (
        <Box m={6} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3><b>Nueva Solicitud</b></h3>
            </Grid>
            <Grid item xs={12}>
              <h5><b>Nivel del Psicotécnico</b></h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[16].id}
                elementType={formElementsArray[16].config.elementType}
                elementConfig={formElementsArray[16].config.elementConfig}
                value={formElementsArray[16].config.value}
                textValid={formElementsArray[16].config.textValid}
                invalid={!formElementsArray[16].config.valid}
                shouldValidate={formElementsArray[16].config.validation}
                touched={formElementsArray[16].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[16].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>
            <Grid item xs={12}>
              <h5><b>Feedback</b></h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[17].id}
                elementType={formElementsArray[17].config.elementType}
                elementConfig={formElementsArray[17].config.elementConfig}
                value={formElementsArray[17].config.value}
                textValid={formElementsArray[17].config.textValid}
                invalid={!formElementsArray[17].config.valid}
                shouldValidate={formElementsArray[17].config.validation}
                touched={formElementsArray[17].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[17].id, newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>

            <Grid item xs={12}>
              <Input
                key={"editpsicotecnico-" + formElementsArray[18].id}
                elementType={formElementsArray[18].config.elementType}
                elementConfig={formElementsArray[18].config.elementConfig}
                value={formElementsArray[18].config.value}
                textValid={formElementsArray[18].config.textValid}
                invalid={!formElementsArray[18].config.valid}
                shouldValidate={formElementsArray[18].config.validation}
                touched={formElementsArray[18].config.touched}
                changed={(event, newValue) => this.inputChangedHandler(event, formElementsArray[18].id, newValue)}
              />
            </Grid>
          </Grid>
        </Box>
      );
        break;
    }

  }
  ///STEPPER///

  handleSubmitNewPsicotecnico = (event) => {
    event.preventDefault();

    console.log("insert " + this.state.activeStep);
    console.log(this.state.newPsicotecnicoForm);



    Database.post(`/insert-issue`, {
      facturar_a: this.state.newPsicotecnicoForm.facturar_a.value, pedido_a_nombre_de: this.state.newPsicotecnicoForm.pedido_a_nombre_de.value,
      pedido_contacto: this.state.newPsicotecnicoForm.contacto1.value, pedido_mail: this.state.newPsicotecnicoForm.mail1.value,
      pedido_contacto2: this.state.newPsicotecnicoForm.contacto2.value, pedido_mail2: this.state.newPsicotecnicoForm.mail2.value,
      pedido_contacto3: this.state.newPsicotecnicoForm.contacto3.value, pedido_mail3: this.state.newPsicotecnicoForm.mail3.value,
      nombre: this.state.newPsicotecnicoForm.nombre.value, telefono: this.state.newPsicotecnicoForm.telefono.value, mail: this.state.newPsicotecnicoForm.mail.value,
      puesto: this.state.newPsicotecnicoForm.puesto.value, comentario_cliente: this.state.newPsicotecnicoForm.comentario_cliente.value,
      objetivo: this.state.newPsicotecnicoForm.objetivo.value, nivel: this.state.newPsicotecnicoForm.nivel.value, feedback: this.state.newPsicotecnicoForm.feedback.value ? "10047" : "10048"
    }, this)
      .then(res => {

        toast.success("Su solicitud ha sido enviada con éxito!");
        this.setState({
          successSubmit: true,
          formIsValid: false,
        }, () => {
          //this.props.getPsicotecnicosAdmin();
        })
        this.resetNewForm();

        console.log(res);
        console.log(res.response);

        this.handleModal(res.response.id);
      }, err => {
        toast.error(err.message);

      })






  }


  handleModal = rowData => {
    this.setState({
      openSubirArchivoDialog: true,
      subirarchivoRowData: rowData
    })
  }



  inputChangedHandler = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newPsicotecnicoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      if (!updatedOrderForm[inputIdentifier].type)
        formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }

    if (inputIdentifier == "feedback") {

      //  console.log("cambiando feedback: " + newValue);
      if (newValue == true)
        updatedOrderForm["feedback"].value = 1;
      else
        updatedOrderForm["feedback"].value = 0;
    }

    /*
        if (inputIdentifier == "mail") {
          //console.log()
          if (typeof updatedOrderForm["mail"] !== "undefined") {
            let lastAtPos = updatedOrderForm["mail"].lastIndexOf('@');
            let lastDotPos = updatedOrderForm["mail"].lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && updatedOrderForm["mail"].indexOf('@@') == -1 && lastDotPos > 2 && (updatedOrderForm["mail"].length - lastDotPos) > 2)) {
              formIsValidAlt = false;
             // errors["email"] = "Email is not valid";
            }
          }
        }
    */
    this.setState({
      newPsicotecnicoForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    })

  }

  handleCloseSubirArchivo() {
    this.setState({
      openSubirArchivoDialog: false,
      subirarchivoRowData: null
    })
  }

  resetNewForm = (all) => {
    let newState = JSON.parse(JSON.stringify(StateNewPsicotecnico));

    this.setState(
      newState
    )
    this.getPsicotecnicosObjetivos();
    this.getPsicotecnicosNivel();
    //this.getPsicotecnicosType("new", newPsicotecnicoFormAlt);

  }

  getPsicotecnicosObjetivos = () => {
    Database.get('/list-psicotecnicos-objetivos', this)
      .then(res => {

        let resultadoPsicotecnicoType = [...res.result];
        let a = [];
        resultadoPsicotecnicoType.forEach(function (entry) {
          a.push({
            value: entry.id_jira,
            displayValue: entry.descripcion
          });
        })
        let formulario = { ...this.state.newPsicotecnicoForm }
        formulario.objetivo.elementConfig.options = [...a];
        this.setState({
          newPsicotecnicoForm: formulario
        })
      }, err => {
        toast.error(err.message);
      })
  }

  getPsicotecnicosNivel = () => {
    Database.get('/list-psicotecnicos-nivel', this)
      .then(res => {

        let resultadoPsicotecnicoType = [...res.result];
        let a = [];
        resultadoPsicotecnicoType.forEach(function (entry) {
          a.push({
            value: entry.id_jira,
            displayValue: entry.descripcion
          });
        })
        let formulario = { ...this.state.newPsicotecnicoForm }
        formulario.nivel.elementConfig.options = [...a];
        this.setState({
          newPsicotecnicoForm: formulario
        })
      }, err => {
        toast.error(err.message);
      })
  }


  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;
    function mailvalido(valor) {
      let re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
      if (!re.exec(valor)) {
        return false
      }
      else return true;
    }

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.mail && isValid) {
      isValid = value.toString().trim() !== '' && mailvalido(value);
      textValid = 'El mail debe ser válido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }


  handleSubirArchivo = rowData => {
    console.log(rowData);
    // alert(rowData);
    console.log(this.props);
    console.log(this.props.match.url);
    console.log("/admin/psicotecnicoslistado/upload/" + rowData);
    this.props.history.push("/admin/psicotecnicoslistado/upload/" + rowData);

  }


  componentDidMount() {
    console.log(this.state);
    this.getPsicotecnicosObjetivos();
    this.getPsicotecnicosNivel();
  }



  render() {

    const formElementsArray = [];
    for (let key in this.state.newPsicotecnicoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.newPsicotecnicoForm[key]
      });
    }
    return (
      <div>
        <form onSubmit={(event) => {
          console.log("se ejecuto el onSubmit");
          this.handleSubmitNewPsicotecnico(event)

        }}>

          <Card>

            <CardBody>


              <Stepper activeStep={this.state.activeStep}>
                {steps.map((label, index) => {

                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {this.getStepContent(formElementsArray, this.state.activeStep)}
                </Typography>
              </React.Fragment>

              <Box m={6} >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <Button style={{ width: '100%' }} variant="outlined" color="primary" disabled={this.state.activeStep === 0} onClick={() => this.handleBack()}>ATRAS</Button>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    {this.state.activeStep != 2 ? (
                      <Button style={{ width: '100%', backgroundColor: 'black', color: 'white' }} variant="outlined" disabled={this.state.activeStep === 2} onClick={() => { this.handleNext() }}>SIGUIENTE</Button>
                    ) : (<div />)
                    }
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    {this.state.activeStep === 2 ? (
                      <Button style={{ width: '100%' }} variant="contained" disabled={!this.state.formIsValid || this.state.disableAllButtons} type="submit" >ENVIAR SOLICITUD</Button>
                    ) : (<div />)
                    }
                  </Grid>

                </Grid>
              </Box>

            </CardBody>
          </Card>
        </ form>

        <ModalSubirArchivo
          openSubirArchivoDialog={this.state.openSubirArchivoDialog}
          subirarchivoRowData={this.state.subirarchivoRowData}
          handleClose={() => this.handleCloseSubirArchivo()}
          handleSubirArchivo={(rowData) => this.handleSubirArchivo(rowData)}
        />
      </div>
    )
  }


};

export default withRouter(withStyles(styles)(NewPsicotecnico));
