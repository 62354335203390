export const StateListClientes = {
    clientes: [],
    offset:0,
    checked: [],
    menuContext: null,
    botonesAcciones: {
        nuevo: {

            enabled: true,
            texto: 'Nuevo'
        },
        editar: {

            enabled: false,
            texto: 'Editar'
        },
        delete: {

            enabled: false,
            texto: 'Eliminar'
        }
    },
    modalOpen: false,
    openDeleteDialog:false,
    deleteRowData:null,
    isLoading:false




}

export const StateEditCliente = {

    editClienteForm: {
        razon_social: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Razon Social',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        nombre_fantasia: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre Fantasia',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        // username: {
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'text',
        //         label: 'Nombre Usuario',
        //         fullWidth: true
        //     },
        //     value: '',
        //     validation: {
        //         required: true
        //     },
        //     valid: false,
        //     touched: false
        // },
        // password: {
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'password',
        //         label: 'contraseña',
        //         fullWidth: true
        //     },
        //     value: '',
        //     validation: {
        //         minLength: 5,
        //         required: true,

        //     },
        //     valid: false,
        //     touched: false
        // },
        cuit: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'CUIT',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        numero_cliente: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Codigo Interno de Cliente',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },      
        telefono: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        contacto: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        contacto2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto 2',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail 2',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        contacto3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto 3',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail 3',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }

    },
    clienteEdit: null,
    editFormIsValid: false,
    successSubmitEdit: null,
    disableAllButtons:false,
    openChangePass: false

}



export const StateNewCliente = {

    newClienteForm: {
        razon_social: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Razon Social',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        nombre_fantasia: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Nombre Fantasia',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        cuit: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'CUIT',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        numero_cliente: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Codigo Interno de Cliente',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        telefono: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Telefono',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        contacto: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto 1',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mail: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail 1',
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        contacto2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto 2',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail 2',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        contacto3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contacto 3',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        mail3: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Mail 3',
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }
    },

    formIsValid: false,
    successSubmit: null,
    disableAllButtons:false
}


export const ColumnsListado = [
{ title: "Numero Cliente", field: "numero_cliente" },
{ title: "Razon Social", field: "razon_social" },
{ title: "Nombre Fantasia", field: "nombre_fantasia" },
{ title: "CUIT", field: "cuit" },
{ title: "Telefono", field: "telefono" },
{ title: "Contacto", field: "contacto" },
{ title: "Mail", field: "mail" },
{ title: "Contacto 2", field: "contacto2" },
{ title: "Mail 2", field: "mail2" },
{ title: "Contacto 3", field: "contacto3" },
{ title: "Mail 3", field: "mail3" }

];
