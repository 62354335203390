/* eslint-disable */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';



function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}


function getSuggestions(value, suggestions) {

  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep =
        count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));


const Input = (props) => {
  const classes = useStyles();
  let inputElement = null;
  let textValidation = null;
  if (!props.objeto.valid && props.objeto.validation && props.objeto.touched) {
    textValidation = props.objeto.textValid
  }

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, props.objeto.elementConfig.suggestions));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  const handleChange = (event, { newValue }) => {
    props.changed(event, newValue);
  };

  const handleChangeSelect = (event) => {
    props.changed(event);
  };

  // Radio group ->
  const [radio_value, setRadioValue] = React.useState();

  const handleRadioGroupChange = (event, objeto) => {
    setRadioValue(event.target.value);
    objeto.radio_seleccionado = event.target.value;
  }
  // <- Radio group 

  switch (props.objeto.elementType) {
    case ('input'):
      inputElement = <TextField style={{ marginTop: '15px' }}
        {...props.objeto.elementConfig}
        value={props.objeto.value}
        onChange={props.changed} />;
      break;
    case ('textarea'):
      inputElement =
        <TextField style={{ marginTop: '20px' }}
          {...props.objeto.elementConfig}
          value={props.objeto.value}
          onChange={props.changed}
          multiline={true}
        />;
      break;
    case ('checkbox'):
      inputElement =
        <FormControlLabel style={{ marginTop: '20px' }}
          control={<Checkbox value={props.objeto.value}
            {...props.objeto.elementConfig}
            onChange={props.changed}
            checked={props.objeto.value ? true : false}
          />}
          label={props.objeto.elementConfig.label} />
      break;
    case ('select'):
      inputElement = (
        <FormControl style={{ minWidth: '180px', marginTop: '15px' }}>
          <InputLabel >{props.objeto.elementConfig.label}</InputLabel>
          <Select
            {...props.objeto.elementConfig}
            value={props.objeto.value}
            onChange={handleChangeSelect}

          >
            {props.objeto.elementConfig.options.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.displayValue}</MenuItem>

            ))}
          </Select>
        </FormControl>
      );
      break;
    case ('autosuggest'):
      inputElement = (
        <FormControl style={{ minWidth: '180px', marginTop: '15px' }}>
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              value: props.objeto.value,
              onChange: handleChange,
              ...props.objeto.elementConfig
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => {
              return (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )
            }}
          />
        </FormControl>
      );
      break;
    case ('radiogroup'):
      inputElement = (
        <FormControl style={{ minWidth: '180px', marginTop: '15px' }}>
          <RadioGroup row value={props.objeto.radio_value}
            onChange={(event) => handleRadioGroupChange(event, props.objeto)}
            onClick={(event) => props.getRadioSeleccionado(event.target.value)}
          >
            {props.objeto.radio_group_values.map(val => {
              return <FormControlLabel value={val} control={<Radio />} label={val} />
            })
            }
          </RadioGroup>
        </FormControl>
      );
      break;
    default:
      inputElement = <input
        {...props.objeto.elementConfig}
        value={props.objeto.value}
        onChange={props.changed} />;
  }

  return !props.objeto.disabled && (
    <div>
      <label>{props.label}</label>
      { props.textoInicial ? <span style={{ fontWeight:'800',fontSize:'130%',display:'inline-block',paddingTop:'35px',paddingRight:'15px' }}>{props.textoInicial}</span> : null}{inputElement}
      <span style={{ fontSize: '80%', color: 'red' }}>{textValidation}</span>
    </div>
  );

};

export default Input;
