import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from 'moment';

import { Route, Switch} from 'react-router-dom';
// core components
import MaterialTable from "material-table";
import { withStyles } from '@material-ui/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { localization } from "variables/general.js";
import { toast } from 'react-toastify';
import { StateListAuditoria, ColumnsListado } from "./VariablesState";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class Auditoria extends Component {
  state = { ...StateListAuditoria };


  componentDidMount() {
    this.getAuditoriaAdmin();
  }

  getAuditoriaAdmin = () => {
    this.setState({
      isLoading: true
    })

    return Database.get('/list-auditoria',this,null,true)
      .then(res => {
        let resultado = [...res.result];

        resultado = resultado.map(elem => {
          return {
            ...elem,      
            fecha_mostrar: moment(elem.fecha).isValid() ? moment(elem.fecha).format("DD/MM/YYYY HH:mm:ss") : ""
          };
        });


        this.setState({
          isLoading:false,
          auditoria: resultado,
        })
      },err =>{
        toast.error(err.message);
      })
  }

  sendMail = () => {
    this.setState({
      isLoading: true
    })

   return Database.get('/send_mail',this)
      .then(res => {
        this.setState({
          isLoading:false,
        })
      },err =>{
        this.setState({
          isLoading:false,
        })
        toast.error(err.message);
      })
  }

  handlePagination = offset => {
    this.setState({
      offset: offset
    })

  }


  render() {
    let style = {}
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: 'none' }
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite} >Auditoria</h4>
              <p className={this.props.classes.cardCategoryWhite} >
                Listado de Auditoria
                      </p>
            </CardHeader>
            <CardBody>
            <Button onClick={ () => this.sendMail().then(res => this.getAuditoriaAdmin()) } color="primary" disabled={ this.state.isLoading }>Procesar Mails</Button>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoauditoria')} color="primary"><AddIcon /> Nuevo Auditoria</Button> */}
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.auditoria}
                title=""
                localization={localization}

                components={{
                  Container: props => (
                    <Paper elevation={0} {...props} />
                  )
                }}

                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData:true,
                  exportFileName:"Auditoria " + moment().format("DD-MM-YYYY"),
                  exportDelimiter:";",
                  headerStyle: {
                    backgroundColor: "#414141",
                    color: '#FFF'
                  },
                }}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    );
  }
}


export default withStyles(styles)(Auditoria);
