import React, { Component } from "react";
import Input from 'components/Input/Input';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import Database from "variables/Database.js";
import { toast } from 'react-toastify';

import { withStyles } from '@material-ui/styles';

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import { StateEditConfiguracion as StateEditConfiguracion } from "./VariablesState";
import { StateEditMailing as StateEditMailing } from "./VariablesState";



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

//var minimoAnt = null;

class Configuracion extends Component {
  state = JSON.parse(JSON.stringify(StateEditConfiguracion));


getPrimerAlertaVencimiento(item){
  return item.nombre==="alerta_caducidad_primer_aviso";
}

getDocumentacionAutomotores(item){
  return item.nombre==="documentacion_automotores";
}

getDocumentacionEmpleados(item){
  return item.nombre==="documentacion_empleados";
}

getSegundaAlertaVencimiento(item){
  return item.nombre==="alerta_caducidad_segundo_aviso";
}

  getConfiguracionEdit = (id) => {
    Database.get('/list-configuraciones/',this,null,true)
      .then(resultado => {
          if (resultado.result.length > 0) {
            this.setState({
              configuracionEdit: resultado.result
            })
         //   minimoAnt = resultado.result[0].minimo;
            let editConfiguracionFormAlt = { ...this.state.editConfiguracionForm };
            let editConfiguracionFormIntegridadAlt = { ...this.state.editConfiguracionFormIntegridad };
            //alert(resultado.data.result[0].valor);
            console.log(resultado);
           
            editConfiguracionFormAlt.alertaCaducidadPrimera.value = resultado.result.find(this.getPrimerAlertaVencimiento).valor;
            editConfiguracionFormAlt.alertaCaducidadSegunda.value = resultado.result.find(this.getSegundaAlertaVencimiento).valor; 
            editConfiguracionFormIntegridadAlt.documentacionAutomotores.value = ( (resultado.result.find(this.getDocumentacionAutomotores).valor == 0) ? false : true);
            editConfiguracionFormIntegridadAlt.documentacionEmpleados.value = ( (resultado.result.find(this.getDocumentacionEmpleados).valor == 0) ? false : true);

            for (let key in editConfiguracionFormAlt) {
              editConfiguracionFormAlt[key].touched = true;
              editConfiguracionFormAlt[key].valid = true;
            }

            for (let key in editConfiguracionFormIntegridadAlt) {
              editConfiguracionFormIntegridadAlt[key].touched = true;
              editConfiguracionFormIntegridadAlt[key].valid = true;
            }

            this.setState({
              editConfiguracionForm: editConfiguracionFormAlt,
              editConfiguracionFormIntegridad: editConfiguracionFormIntegridadAlt
            })
          }
          else {
            this.setState({
              configuracionEdit: null
            })
          }

      },err => {
        toast.error(err.message);
      })
  }

  handleSubmitEditConfiguracion = (event) => {

    event.preventDefault();


    let objeto = {}
    for (let key in this.state.editConfiguracionForm) {
      objeto[key] = this.state.editConfiguracionForm[key].value
    }

    for (let key in this.state.editConfiguracionFormIntegridad) {
      objeto[key] = (this.state.editConfiguracionFormIntegridad[key].value == 0) ? false : true
    }

    console.log(objeto["alertaCaducidadPrimera"]);

    let objUpdate = [];
    objUpdate = [... this.state.configuracionEdit];

    
    console.log(objUpdate);
    console.log(objUpdate[0].valor);
    console.log(objUpdate.find(this.getPrimerAlertaVencimiento).valor);

    objUpdate.find(this.getPrimerAlertaVencimiento).valor = objeto["alertaCaducidadPrimera"];
    objUpdate.find(this.getSegundaAlertaVencimiento).valor = objeto["alertaCaducidadSegunda"];
    objUpdate.find(this.getDocumentacionAutomotores).valor = objeto["documentacionAutomotores"];
    objUpdate.find(this.getDocumentacionEmpleados).valor = objeto["documentacionEmpleados"];

    console.log(objUpdate);



    Database.post(`/update-configuracion`, objUpdate,this)
      .then(res => {

          toast.success("Configuracion actualizada correctamente");
       //   this.props.getConfiguracionEdit();
          this.setState({
            editFormIsValid: false
          })


      },err => {
        toast.error(err.message);
      })

  }





  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;
console.log(value);
console.log(rules);
    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }




  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editConfiguracionForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
      console.log(updatedOrderForm);
      this.setState({
        editConfiguracionForm: updatedOrderForm,
        formIsValid: formIsValidAlt

      })
  }

  inputEditChangedHandlerIntegridad = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editConfiguracionFormIntegridad
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    if (inputIdentifier == "documentacionAutomotores") {
      
      console.log("cambiando documentacionAutomotores: " + newValue);
      if (newValue == true)
        updatedOrderForm["documentacionAutomotores"].value = 1;
      else
        updatedOrderForm["documentacionAutomotores"].value = 0;
    }

    if (inputIdentifier == "documentacionEmpleados") {
      console.log("cambiando documentacionEmpleados: " + newValue);
      if (newValue == true)
        updatedOrderForm["documentacionEmpleados"].value = 1;
      else
        updatedOrderForm["documentacionEmpleados"].value = 0;
    }

      console.log(updatedOrderForm);
      this.setState({
        editConfiguracionFormIntegridad: updatedOrderForm,
        formIsValid: formIsValidAlt

      })
  }


  // editSingleCosto = value => {
  //   this.props.history.push(this.props.match.url + '/editarcosto/' + value);
  // }


  // resetEditForm = () => {
  //   let editCostoFormAlt = { ...this.state.editCostoForm };
  //   for (let key in editCostoFormAlt) {
  //     editCostoFormAlt[key].value = ''
  //   }

  //   this.setState({
  //     editCostoForm: editCostoFormAlt,
  //     editFormIsValid: false
  //   })


  // }


  componentDidMount() {
    this.getConfiguracionEdit(this.props.match.params.idconfiguracion);
   // this.getCategorias();
  }



  render() {
    const formElementsArray = [];
    for (let key in this.state.editConfiguracionForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editConfiguracionForm[key]
      });
    }

    const formElementsArrayMailing = [];
    for (let key in this.state.editConfiguracionFormMailing) {
      formElementsArrayMailing.push({
        id: key,
        config: this.state.editConfiguracionFormMailing[key]
      });
    }

    const formElementsArrayIntegridad = [];
    for (let key in this.state.editConfiguracionFormIntegridad) {
      formElementsArrayIntegridad.push({
        id: key,
        config: this.state.editConfiguracionFormIntegridad[key]
      });
    }


    return (

      <form onSubmit={(event) => {
        this.handleSubmitEditConfiguracion(event)

      } }>


        <Card>

        <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Mailing</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              
      </p>
          </CardHeader>
          <CardBody>

            <div className="mt-3 mb-3">
              {formElementsArrayMailing.map(formElement => (
                <Input
                  key={formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputEditChangedHandler(event, formElement.id)}
                  />
              ))}
            </div>

          </CardBody>

          <br></br><br></br>

          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Integridad</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              
      </p>
          </CardHeader>
          <CardBody>

            <div className="mt-3 mb-3">
              {formElementsArrayIntegridad.map(formElement => (
                <Input
                  key={formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event, newValue) => this.inputEditChangedHandlerIntegridad(event, formElement.id, newValue)}
                  />
              ))}
            </div>

          </CardBody>

          <br></br><br></br>
          
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Alertas</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              
      </p>
          </CardHeader>
          <CardBody>

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputEditChangedHandler(event, formElement.id)}
                  />
              ))}
            </div>

            {/* <Button style={{ marginTop: '25px' }} color="info" onClick={() => this.props.history.push('/admin/configuracion')} ><ArrowBack />Volver</Button> */}
            <Button style={{ marginTop: '25px' }} color="primary" variant="contained" type="submit" ><Save /> Guardar</Button>


          </CardBody>
        </Card>


      </ form>


    )
  }



};

export default withRouter(withStyles(styles)(Configuracion));
