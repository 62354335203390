import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from 'moment';

import { Route, Switch } from 'react-router-dom';
// core components
import MaterialTable from "material-table";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUpload from "@material-ui/icons/CloudUpload";
import SearchIcon from '@material-ui/icons/Search';

// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import lightGreen from '@material-ui/core/colors/lightGreen';

//import NewUser from "./components/NewPsicotecnico";
import EditPsicotecnico from "./components/EditPsicotecnico";
import ModalDelete from "./components/ModalDelete"
import { localization } from "variables/general.js";

import { toast } from 'react-toastify';


import { StateListPsicotecnicos, ColumnsListado } from "./VariablesState";
import Upload from "./components/UploadArchivo";




const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class Psicotecnicos extends Component {
  state = { ...StateListPsicotecnicos };


  componentDidMount() {
    this.getPsicotecnicosAdmin();
  }



  // handleToggle = value => {
  //   const currentIndex = this.state.checked.indexOf(value);
  //   const newChecked = [...this.state.checked];
  //   let deleteEnabled = false;
  //   let editEnabled = false;
  //   const botonesAcc = { ...this.state.botonesAcciones }
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   if (newChecked.length > 0) {
  //     deleteEnabled = true;
  //     if (newChecked.length === 1)
  //       editEnabled = true;
  //   }
  //   botonesAcc.editar.enabled = editEnabled;
  //   botonesAcc.delete.enabled = deleteEnabled;
  //   this.setState({
  //     botonesAcciones: botonesAcc,
  //     checked: newChecked
  //   })

  // };

  menuHandleClose = (value) => {
    this.setState({
      menuContext: null
    })
  }

  menuHandleItemClick = (value) => {
    const newItem = { ...this.state.botonesAcciones[value] };
    let menuContext = { ...this.state.menuContext };
    if (newItem.enabled) {
      menuContext = null;

    /*  if (value === 'nuevo') {
        this.setState({
          menuContext: menuContext
        })
        this.props.history.push(this.props.match.url + '/nuevopsicotecnico');
      }*/

      if (value === 'editar' && this.state.checked.length === 1) {
        this.setState({
          menuContext: menuContext
        })
        let idpsicotecnico = this.state.checked[0].id;
        this.props.history.push(this.props.match.url + '/editarpsicotecnico/' + idpsicotecnico);
      }
    }
  }

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    })
  }
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE PSICOTECNICOS
  ////////////////////////
  ////////////////////////
  getPsicotecnicosAdmin = () => {
    this.setState({
      isLoading: true
    })

    console.log("getPsicotecnicosAdmin");

    Database.get('/list-my-issues', this, null, true)
      .then(res => {
        console.log(res);
        console.log(res.response.issues);
        let resultado = [...res.response.issues];
        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            //   vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
            proyecto_mostrar: elem.fields.customfield_10086 != null ? elem.fields.customfield_10086 : "-",
            asignado_mostrar: elem.fields.customfield_10033 != null ? elem.fields.customfield_10033.value.substring(0, elem.fields.customfield_10033.value.indexOf("|")) : ""
          };
        });

        this.setState({
          isLoading: false,
          psicotecnicos: resultado,
          checked: [],
          menuContext: null
        })


      }, err => {
        toast.error(err.message);

      })



  }



  // editSingleUser = value => {
  //   this.props.history.push(this.props.match.url + '/editarpsicotecnico/' + value);
  // }

  handlePagination = offset => {
    this.setState({
      offset: offset
    })

  }

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    })
  }

  menuHandleClose = (value) => {
    this.setState({
      menuContext: null
    })
  }

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    })
  }

  handleDeleteUser = rowData => {
    console.log(rowData.id);
    
    Database.post('/mensaje-issue/' + rowData.id + '/baja_issue', null, null).then(res => {
      this.setState({
        //   users: users,
        openDeleteDialog: false
      }, () => {
        toast.success("La solicitud de cancelación fue realizada con exito!.");
      })


    }, err => {
      console.log(err.message);
      toast.error(err.message);
    })

//toast.success("La solicitud de cancelación fue realizada con exito!. Recibirá respuesta a la brevedad.");
  }

  existeInformeFinal = rowData => {
//    console.log(rowData.id);

    for (let archivo of rowData.fields.attachment) {
   //   console.log(archivo.filename);
      if (archivo.filename.toLowerCase().indexOf("informe") >= 0)
        if (archivo.filename.toLowerCase().indexOf("final") >= 0)
          return true;
      // if(archivo.filename == "informefinal.pdf")
      // return true;
    }

    return false;
  }


  handleBajarInforme = rowData => {
    console.log("bajando el informe del issue numero: " + rowData.id);

    let fileToDownload = "";

    for (let archivo of rowData.fields.attachment) {
      console.log(archivo.filename);
      if (archivo.filename.toLowerCase().indexOf("informe") >= 0)
        if (archivo.filename.toLowerCase().indexOf("final") >= 0) {
          fileToDownload = archivo.id;
          //    return;
        }
    }

    console.log(fileToDownload);


    if (fileToDownload != "") {

      // Database.get('/issuefile/'+fileToDownload, this, null, true)
      //   //  Database.post('/delete-issue', { id: rowData.id }, this)
      //   .then(res => {
      //     console.log(res);
      //   }, err => {
      //     toast.error(err.message);

      //   })

      var link = document.createElement("a");
      /// link.download = name;
      link.href = '/issuefile/' + fileToDownload;

      console.log(link.href);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;

      console.log("termino");
    }



  }



  handleBajarInformeManual = rowData => {

    //creating an invisible element
    var element = document.createElement('a');
    element.setAttribute('href',
      'data:text/plain;charset=utf-8, '
      + encodeURIComponent(rowData.fields.attachment[0].filename));
    element.setAttribute('download', rowData.fields.attachment[0].filename);

    console.log(element);
    console.log(rowData.fields.attachment[0].filename);
    // Above code is equivalent to
    // <a href="path of file" download="file name">

    document.body.appendChild(element);

    //onClick property
    element.click();

    document.body.removeChild(element);
  }


  render() {
    let style = {}
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: 'none' }
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary" >
              <h4 className={this.props.classes.cardTitleWhite} >Evaluaciones Psicológicas</h4>
              <p className={this.props.classes.cardCategoryWhite} >
                Listado de Evaluaciones
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevopsicotecnico')} color="primary"><AddIcon /> Nuevo Psicotecnico</Button> */}
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.psicotecnicos}
                title=""
                localization={localization}

                actions={[
                  {
                    icon: SearchIcon,
                    tooltip: 'Visualizar',
                    onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/editarpsicotecnico/' + rowData.id)
                  },
                  {
                    icon: CloudUpload,
                    tooltip: 'Subir Archivo',
                    onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/upload/' + rowData.id)
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Solicitar Baja',
                    onClick: (event, rowData) => this.handleDeleteButton(rowData)

                  },
                  rowData => (
                    {
                      icon: DescriptionIcon,
                      tooltip: 'Bajar Informe Final',
                      onClick: (event, rowData) => this.handleBajarInforme(rowData),
                      disabled: this.existeInformeFinal(rowData) != true


                    })

                ]}
                components={{
                  Container: props => (
                    <Paper elevation={0} {...props} />
                  )
                }}

                options={{
                  filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: "Psicotecnicos " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: "#414141",
                    color: '#FFF'
                  },
                }}
              />
            </CardBody>
          </Card>

          <Switch>
            <Route path={this.props.match.url + "/upload/:idpsicotecnico"} render={() =>

              <Upload

                getPsicotecnicosAdmin={() => this.getPsicotecnicosAdmin()}
              //     handleListNewUser={(rowData) => this.handleListNewUser(rowData)}


              />}
            />

             <Route path={this.props.match.url + "/editarpsicotecnico/:idpsicotecnico"} render={() =>

              <EditPsicotecnico
                orderForm={this.state.editPsicotecnicoForm}
                editFormIsValid={this.state.editFormIsValid}
                successSubmitEdit={this.state.successSubmitEdit}


                handleSubmitEditPsicotecnico={(event) => { this.handleSubmitEditPsicotecnico(event) } }
                inputEditChangedHandler={(event, inputIdentifier) => this.inputEditChangedHandler(event, inputIdentifier)}
                getUserEdit={(id) => { this.getUserEdit(id) } }
                resetEditForm={this.resetEditForm}
                reloadPsicotecnicos={this.reloadPsicotecnicos}
                getPsicotecnicosAdmin={() => this.getPsicotecnicosAdmin()}



                />}
              /> 

          </Switch>


        </GridItem>

        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={(rowData) => this.handleDeleteUser(rowData)}
        />


      </GridContainer>

    );
  }
}


export default withStyles(styles)(Psicotecnicos);
