export const StateListado = {
  costos: [],
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
}

export const ColumnsListado = [
  { title: "Alerta Costo", field: "nombre" }
];

export const StateEditConfiguracion =
{
  editFormIsValid: false,
  costoEdit: null,
  arrayConfiguraciones: [],
  editConfiguracionForm: {
    alertaCaducidadPrimera: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        label: 'Primer Alerta Caducidad (dias previos a vencimiento)',
        fullWidth: true
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    alertaCaducidadSegunda: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        label: 'Segunda Alerta Caducidad (dias previos a vencimiento)',
        fullWidth: true
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  editConfiguracionFormMailing: {
    mailingUno: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        label: 'Mailing Primer Envio (HHMM)',
        fullWidth: true
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    mailingDos: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        label: 'Mailing Segundo Envio (HHMM)',
        fullWidth: true
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    mailingTres: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        label: 'Mailing Tercer Envio (HHMM)',
        fullWidth: true
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  editConfiguracionFormIntegridad: {
    documentacionAutomotores: {
      elementType: 'checkbox',
      elementConfig: {
        type: 'checkbox',
        label: 'Tener en cuenta estado de la documentación de los automotores',
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    documentacionEmpleados: {
      elementType: 'checkbox',
      elementConfig: {
        type: 'checkbox',
        label: 'Tener en cuenta estado de la documentación de los choferes',
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  }

  


}
