// Prefijos (tipo namespaces) usados
// Evitar chocar aca - Preferiblemente agregar prefijos a los actiontypes - <No hace falta agregarlos
// a las funciones que ya estaban definidas aunque no estaria tampoco de mas hacerlo>
// No hace falta prefijar las funciones de los reducers ya que las obtenemos mediante los actiontypes
// ---------------
// Prefijos Usados
// ---------------
// IM -> Importacion Masiva
// 
// 
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADD = 'ADD';
export const SUBTRACT = 'SUBTRACT';
export const STORE_RESULT = 'STORE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';


// 
// Alertas
// 
export const ALERTAS_SET_VENCIMIENTO = 'ALERTAS_SET_VENCIMIENTO';
export const ALERTAS_SET_UMBRAL = 'ALERTAS_SET_UMBRAL';
