import React, { Component } from "react";
import Input from 'components/Input/Input';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { StateNewReclutamiento } from "../VariablesState";
import Files from 'react-files'

import Database from "variables/Database.js";

import { toast } from 'react-toastify';

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import BackupIcon from '@material-ui/icons/Backup';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class NewReclutamiento extends Component {
  state =JSON.parse(JSON.stringify(StateNewReclutamiento));


  handleSubmitNewReclutamiento = async (event) => {
    event.preventDefault();
    //alert("inserto la solicitud: " + this.state.newReclutamientoForm.perfil.value);

    this.setState({
      isLoading: true
    })

    if (this.state.idReclutamiento == -1) {// si no se subio un archivo entonces no se grabo como borrador el reclutamiento todavía => lo grabo
      await Database.post(`/insert-reclutamiento`,
        {
          perfil: this.state.newReclutamientoForm.perfil.value, observaciones: this.state.newReclutamientoForm.observaciones.value, referente_cliente: this.state.newReclutamientoForm.referente_cliente.value
        },
        this)
        .then(res => {

          console.log(res.result[0]);
          console.log(res.result[0][0].id);
        //  id_reclutamiento = res.result[0][0].id;
          //    toast.success("La solicitud de reclutamiento se ha creado con exito!");
          this.setState({
            idReclutamiento: res.result[0][0].id,
            //    successSubmit: true,
            //    formIsValid: false,
          })
          //this.resetNewForm();


        }, err => {
          toast.error(err.message);

        })
    }
    console.log("hola matias");
    console.log(this.state.idReclutamiento);
    Database.post(`/reclutamiento-activar/`,
    {
      id: this.state.idReclutamiento, 
      estado:1
    }, this)
      .then(res => {

          toast.success("La solicitud de reclutamiento se ha creado con exito!");
          this.setState({
            successSubmit: true,
            formIsValid: false,
            isLoading: false,
            idReclutamiento: -1
          })
          this.resetNewForm();


      },err => {
        toast.error(err.message);

      })

    /*
    Database.post(`/insert-issue`, { nombre: this.state.newReclutamientoForm.nombre.value, telefono: this.state.newReclutamientoForm.telefono.value,  mail: this.state.newReclutamientoForm.mail.value,  
      puesto: this.state.newReclutamientoForm.puesto.value, comentario_cliente: this.state.newReclutamientoForm.comentario_cliente.value,
      objetivo: this.state.newReclutamientoForm.objetivo.value, nivel: this.state.newReclutamientoForm.nivel.value },this)
      .then(res => {

          toast.success("La solicitud fue creada con exito!");
          this.setState({
            successSubmit: true,
            formIsValid: false,
          },()=>{
              //this.props.getReclutamientoAdmin();
          })
          this.resetNewForm();


      },err => {
        toast.error(err.message);

      })*/
  }


  inputChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newReclutamientoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newReclutamientoForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    })

  }

  resetNewForm = (all) => {
    let newReclutamientoFormAlt = { ...this.state.newReclutamientoForm };
    let successSubmit = this.state.successSubmit;
    for (let key in newReclutamientoFormAlt) {
      newReclutamientoFormAlt[key].value = ''
    }
    if (all)
      successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      formIsValid: false
    })
    //this.getReclutamientoType("new", newReclutamientoFormAlt);

  }


  //// ARCHIVO ADJUNTO

   onFilesDocumentoChange = async (files) => {
    console.log(files)
    this.setState({
      files: files
    })
    console.log(this.state.files);

    const formData = new FormData();
    formData.append('documento', files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    //var id = this.props.match.params.id;//buscar id

    //guardo el pedido de reclutamiento en estado borrador (-1)

    let id_reclutamiento = -1;

    await Database.post(`/insert-reclutamiento`, 
    {
      /*id_cliente: 35, */perfil: this.state.newReclutamientoForm.perfil.value, observaciones: this.state.newReclutamientoForm.observaciones.value, referente_cliente: this.state.newReclutamientoForm.referente_cliente.value
    },
      this)
      .then(res => {

        console.log(res.result[0]);
        console.log(res.result[0][0].id);
        id_reclutamiento = res.result[0][0].id;
      //    toast.success("La solicitud de reclutamiento se ha creado con exito!");
          this.setState({
            idReclutamiento : id_reclutamiento, 
        //    successSubmit: true,
        //    formIsValid: false,
          })
          //this.resetNewForm();


      },err => {
        toast.error(err.message);

      })



    Database.post('/insert-documento/' + id_reclutamiento + "/" + files[0].name, formData, this, false, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

        .then(res => {
           // setIsLoading(false);
            toast.success("El archivo " + files[0].name + " se ha subido con exito!");
           // callback.bind(this)(file_name);
           this.setState({
            archivoSubido: true
           })
          console.log(res);

        }, err => {
        //    setIsLoading(false);
            toast.error(err.message)

        })

  }

  onFilesDocumentoError = (error, file) => {
    toast.warn('Error al subir el archivo ' + error.code + ': ' + error.message);
    console.log('error code ' + error.code + ': ' + error.message)
  }

//// ARCHIVO ADJUNTO

  

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }

  componentDidMount() {

  }



  render() {

    const formElementsArray = [];
    for (let key in this.state.newReclutamientoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.newReclutamientoForm[key]
      });
    }
    return (

      <form onSubmit={(event) => {
        this.handleSubmitNewReclutamiento(event)

      } }>





        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nueva Búsqueda</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Solicitud de Nueva Búsqueda
      </p>
          </CardHeader>
          <CardBody>

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"newreclutamiento-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputChangedHandler(event, formElement.id)}
                  />
              ))}
            </div>
            <div className="files">
                <Files
                  className='files-dropzone'
                  onChange={this.onFilesDocumentoChange}
                  onError={this.onFilesDocumentoError}
                  accepts={['image/png', '.pdf', 'audio/*', '.docx',  '.doc',  '.jpg']}
                  multiple
                  maxFiles={3}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <Button disabled={this.state.archivoSubido} style={{ marginTop: '25px' }} color="primary" variant="contained" ><BackupIcon />&nbsp; Adjuntar Descripción del Perfil</Button>
                </Files>
                </div>

            <Button style={{ marginTop: '25px' }} variant="outlined" color="info" onClick={() => this.props.history.goBack()} ><ArrowBack />Volver</Button>
            <Button style={{ marginTop: '25px', marginLeft:'25px' }} color="primary" variant="contained" disabled={!this.state.formIsValid || this.state.disableAllButtons} type="submit" ><Save /> Guardar</Button>


          </CardBody>
        </Card>



      </ form>


    )
  }


};

export default withRouter(withStyles(styles)(NewReclutamiento));
