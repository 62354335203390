import React, { Component } from "react";
import Input from 'components/Input/Input';
import { inputAllChangedHandler  } from '../../../variables/input'
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { StateNewReclutamientoCantoli } from "../VariablesState";
import Files from 'react-files'

import Database from "variables/Database.js";

import { toast } from 'react-toastify';
import ModalDeleteCantoli from "./ModalDeleteCantoli"

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class NewReclutamientoCantoli extends Component {
  state = JSON.parse(JSON.stringify(StateNewReclutamientoCantoli));


  handleSubmitNewReclutamientoCantoli = async (event) => {
    event.preventDefault();
    //alert("inserto la solicitud: " + this.state.newReclutamientoForm.perfil.value);

    this.setState({
      isLoading: true
    })

    if(this.state.idReclutamiento != -1 && this.state.isEdit) {

     try {
      let res = await Database.post(`/update-reclutamiento-cantoli`,
      {
        id:this.state.idReclutamiento,
        id_cliente: this.state.newReclutamientoCantoliForm.cliente.value, 
        perfil: this.state.newReclutamientoCantoliForm.perfil.value, 
        observaciones: this.state.newReclutamientoCantoliForm.observaciones.value, 
        referente_cliente: this.state.newReclutamientoCantoliForm.referente_cliente.value,
        referente_rrhh: this.state.newReclutamientoCantoliForm.referente_rrhh.value,
        referente_rrhh_nombre: this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value) && this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value).displayValue ,
            referente_rrhh_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.value,
            referente_rrhh_nombre_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value) && this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value).displayValue,
        estado: this.state.newReclutamientoCantoliForm.estado.value
      },
      this);

      toast.success("La solicitud de reclutamiento se ha actualizado con exito!");
      if(this.props.getReclutamientoAdmin)
        this.props.getReclutamientoAdmin();
    } catch(err) {
      toast.error(err.message);
    }
    } else {

      if(this.state.idReclutamiento != -1) {

        try {
          let res = await Database.post(`/update-reclutamiento-cantoli`,
          {
            id:this.state.idReclutamiento,
            id_cliente: this.state.newReclutamientoCantoliForm.cliente.value, 
            perfil: this.state.newReclutamientoCantoliForm.perfil.value, 
            observaciones: this.state.newReclutamientoCantoliForm.observaciones.value, 
            referente_cliente: this.state.newReclutamientoCantoliForm.referente_cliente.value,
            referente_rrhh: this.state.newReclutamientoCantoliForm.referente_rrhh.value,
            referente_rrhh_nombre: this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value) && this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value).displayValue ,
            referente_rrhh_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.value,
            referente_rrhh_nombre_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value) && this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value).displayValue,
            estado: this.state.newReclutamientoCantoliForm.estado.value
          },
          this);
    
          if(this.props.getReclutamientoAdmin)
            this.props.getReclutamientoAdmin();
        } catch(err) {
          toast.error(err.message);
        }


      } else {

        try {
          let res = await Database.post(`/insert-reclutamiento-cantoli`,
            {
              id_cliente: this.state.newReclutamientoCantoliForm.cliente.value, 
              perfil: this.state.newReclutamientoCantoliForm.perfil.value, 
              observaciones: this.state.newReclutamientoCantoliForm.observaciones.value, 
              referente_cliente: this.state.newReclutamientoCantoliForm.referente_cliente.value,
              referente_rrhh: this.state.newReclutamientoCantoliForm.referente_rrhh.value,
              referente_rrhh_nombre: this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value) && this.state.newReclutamientoCantoliForm.referente_rrhh.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh.value).displayValue ,
            referente_rrhh_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.value,
            referente_rrhh_nombre_2: this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value) && this.state.newReclutamientoCantoliForm.referente_rrhh_2.elementConfig.options.find(elem => elem.value == this.state.newReclutamientoCantoliForm.referente_rrhh_2.value).displayValue,
              
            },
            this);
           
    
            //  console.log(res.result[0]);
              console.log(res.result[0][0].id);
           //   id_reclutamiento = res.result[0][0].id;
              //    toast.success("La solicitud de reclutamiento se ha creado con exito!");
              this.setState({
                idReclutamiento: res.result[0][0].id,
                //    successSubmit: true,
                //    formIsValid: false,
              })
              //this.resetNewForm();
    
          } catch(err)
            {
              toast.error(err.message);
            }       
          

      }
    
  
      try {
        
        let url = '/reclutamiento-activar'
       
         
        let res2 = await Database.post(url,
        {
          id: this.state.idReclutamiento, 
          estado: this.state.newReclutamientoCantoliForm.estado.value
        }, this)
  
      
          toast.success("La solicitud de reclutamiento se ha creado con exito!");
          this.setState({
            successSubmit: true,
            formIsValid: false,
            idReclutamiento: -1,
            adjuntoDesactivado: true
          })
          this.resetNewForm();
          if(this.props.getReclutamientoAdmin)
            this.props.getReclutamientoAdmin();

        
        } catch(err) {
          toast.error(err.message);
  
        }
    


    }


    this.setState({
     isLoading:false
    })
       


  }


  inputChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newReclutamientoCantoliForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };


    if(inputIdentifier == "cliente"){
      this.setState({adjuntoDesactivado: false});
    }

    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt = updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newReclutamientoCantoliForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    })

  }

  resetNewForm = (all) => {
    let newReclutamientoCantoliFormAlt = { ...this.state.newReclutamientoCantoliForm };
    let successSubmit = this.state.successSubmit;
    for (let key in newReclutamientoCantoliFormAlt) {
      newReclutamientoCantoliFormAlt[key].value = '';
      if(key == "perfil"){
        newReclutamientoCantoliFormAlt[key].valid = false;
        newReclutamientoCantoliFormAlt[key].touched = false;
      }
      if(key == "estado") {
        newReclutamientoCantoliFormAlt[key].value = '1';
      }

    }

    if (all)
      successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      newReclutamientoCantoliForm: newReclutamientoCantoliFormAlt,
      formIsValid: false
    })
    //this.getReclutamientoType("new", newReclutamientoCantoliFormAlt);

  }


  //// ARCHIVO ADJUNTO

  onFilesDocumentoChange = async (files) => {
    console.log(files)
    this.setState({
      files: files
    })
    console.log(this.state.files);

    const formData = new FormData();
    formData.append('documento', files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    //var id = this.props.match.params.id;//buscar id

    //guardo el pedido de reclutamiento en estado borrador (-1)

    let id_reclutamiento = this.state.idReclutamiento;
   if(id_reclutamiento == -1) {
    try {
    let res = await Database.post(`/insert-reclutamiento-cantoli`,
      {
        id_cliente: this.state.newReclutamientoCantoliForm.cliente.value

      },
      this);
      

        console.log(res.result[0]);
        console.log(res.result[0][0].id);
        id_reclutamiento = res.result[0][0].id;
        //    toast.success("La solicitud de reclutamiento se ha creado con exito!");
        this.setState({
          idReclutamiento: id_reclutamiento,
          //    successSubmit: true,
          //    formIsValid: false,
        })
        //this.resetNewForm();

      } catch (err) {
        toast.error(err.message);
      }
     
    }

      try {



      let res2 =  await Database.post('/insert-documento/' + id_reclutamiento + "/" + files[0].name, formData, this, false, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
        toast.success("El archivo " + files[0].name + " se ha subido con exito!");
        // callback.bind(this)(file_name);
        this.setState({
          archivoSubido: true,
          adjuntoDesactivado: true
        })
        console.log(res2);
    

  } catch (err)
{
  toast.error(err.message)

}

}
  onFilesDocumentoError = (error, file) => {
    toast.warn('Error al subir el archivo ' + error.code + ': ' + error.message);
    console.log('error code ' + error.code + ': ' + error.message)
  }

  //// ARCHIVO ADJUNTO



  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== '';
      textValid = 'El campo es requerido'
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = 'Supera el maximo de caracteres';
    }

    return { isValid: isValid, textValid: textValid };
  }

  cargarClientes = () => {
    this.setState({
      isLoading: true
    })

    Database.get('/list-clientes', this)
      .then(res => {

        let resultadoUserType = [...res.result];
        let a = [];
        resultadoUserType.forEach(function (entry) {
          a.push({
            value: entry.id,
            displayValue: entry.razon_social
          });
        })
        let formulario = { ...this.state.newReclutamientoCantoliForm }
        formulario.cliente.elementConfig.options = [...a];
        this.setState({
          newReclutamientoCantoliForm: formulario,
          isLoading: false
        })

      }, err => {
        this.setState({
          isLoading: false
        })
        toast.error(err.message);
      })
  }

  cargarReferenteRRHH = () => {
    this.setState({
      isLoading: true
    })

    Database.get('/get-reclutamiento-campos/referente_cantoli', this)
      .then(res => {
        console.log(res.response);
        let resultadoUserType = [...res.response];
        let a = [];
        resultadoUserType.forEach(function (entry) {
          a.push({
            value: entry.id,
            displayValue: entry.value
          });
        })
        let formulario = { ...this.state.newReclutamientoCantoliForm }
        formulario.referente_rrhh.elementConfig.options = [...a];
        this.setState({
          newReclutamientoCantoliForm: formulario,
          isLoading: false
        })

        console.log("termino");

      }, err => {
        this.setState({
          isLoading: false
        })
        toast.error(err.message);
      })
  }


  cargarReferenteRRHH_2 = () => {
    this.setState({
      isLoading: true
    })

    Database.get('/get-reclutamiento-campos/referente_cantoli_2', this)
      .then(res => {
        console.log("empezo");
        console.log(res.response);
        let resultadoUserType = [...res.response];
        let a = [];
        resultadoUserType.forEach(function (entry) {
          a.push({
            value: entry.id,
            displayValue: entry.value
          });
        })
        let formulario = { ...this.state.newReclutamientoCantoliForm }
        formulario.referente_rrhh_2.elementConfig.options = [...a];
        this.setState({
          newReclutamientoCantoliForm: formulario,
          isLoading: false
        })

       
      }, err => {
        this.setState({
          isLoading: false
        })
       
        toast.error(err.message);
      })
  }

  cargarReclutamiento(id) {

    Database.get('/datos-reclutamiento/' + id, this)
    .then(res => {
      this.setState({
        isLoading: true,
      })
      
      let data = res.result && res.result[0];
      
      let orderFormCopy = { ...this.state.newReclutamientoCantoliForm }

      
      orderFormCopy.cliente.value = data.id_cliente || ""; 
      orderFormCopy.perfil.value = data.perfil || "";
      orderFormCopy.observaciones.value = data.observaciones || ""; 
      orderFormCopy.referente_cliente.value = data.referente_cliente || "";
      orderFormCopy.referente_rrhh.value = data.id_referente_rrhh_jira || "";
      orderFormCopy.referente_rrhh_2.value = data.id_referente_rrhh_jira_2 || "";
      orderFormCopy.estado.value = data.estado || "";
      
     let { orderForm, formIsValid } = inputAllChangedHandler(orderFormCopy);

      this.setState({
        rowData: data,
        isLoading: false,
        isEdit:true,
        idReclutamiento:id,
        newReclutamientoCantoliForm:orderForm,
        formIsValid: formIsValid
      })   
      
  },err => {
    this.setState({
      isLoading: false
    })
   
    toast.error(err.message);

  });
}

openDeleteDialog() {
  this.setState({
    openDeleteDialog:true
  })
}

handleModalClose() {
  this.setState({
    openDeleteDialog:false
  })
}

async handleDeleteReclutamiento(rowData) {
  let url = '/reclutamiento-delete'
  this.setState({
    isLoading: true
  })
   try {            
  let res = await Database.post(url,
  {
    id: rowData.id, 
    estado: -1
  }, this)

  this.props.history.replace('/admin/cantolireclutamientolistado');
  this.props.getReclutamientoAdmin();

} catch (err) {
  this.setState({
    isLoading: false
  })
 
  toast.error(err.message);
}

  
}


  componentDidMount() {
    
    if(this.props.match.params.idReclutamiento) {
      this.cargarReclutamiento(this.props.match.params.idReclutamiento);
      this.setState({
        adjuntoDesactivado:false
      })

    }
    this.cargarClientes();
    this.cargarReferenteRRHH();
    this.cargarReferenteRRHH_2();

  }



  render() {

    const formElementsArray = [];
    for (let key in this.state.newReclutamientoCantoliForm) {
      formElementsArray.push({
        id: key,
        config: this.state.newReclutamientoCantoliForm[key]
      });
    }
    return (
      <div>
      <form onSubmit={(event) => {
        this.handleSubmitNewReclutamientoCantoli(event)

      }}>

        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nueva Búsqueda</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Solicitud de Nueva Búsqueda
            </p>
          </CardHeader>
          <CardBody>

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"newreclutamientocantoli-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) => this.inputChangedHandler(event, formElement.id)}
                />
              ))}
            </div>
            <Button  style={{ marginTop: '25px' }} disabled= { !this.state.rowData } onClick={ () => this.openDeleteDialog() } color="secondary" variant="outlined" ><DeleteIcon />Eliminar</Button>
            <div className="files">
              <Files
                className='files-dropzone'
                onChange={this.onFilesDocumentoChange}
                onError={this.onFilesDocumentoError}
                accepts={['image/png', '.pdf', 'audio/*', '.docx', '.doc', '.jpg']}
                multiple={ false }
                maxFileSize={10000000}
                minFileSize={0}
               // enabled={!this.state.adjuntoDesactivado}
                clickable={!this.state.adjuntoDesactivado}
              >
                <Button disabled={this.state.adjuntoDesactivado} style={{ marginTop: '25px' }} color="primary" variant="contained" ><BackupIcon />&nbsp; Adjuntar Descripción del Perfil</Button>
              </Files>
            </div>

            <Button style={{ marginTop: '25px' }} variant="outlined" color="info" onClick={() => this.props.history.goBack()} ><ArrowBack />Volver</Button>
            <Button style={{ marginTop: '25px', marginLeft: '25px' }} color="primary" variant="contained" disabled={!this.state.formIsValid || this.state.isLoading} type="submit" ><Save /> Guardar</Button>


          </CardBody>
        </Card>



      </ form>

    <ModalDeleteCantoli
      openDeleteDialog={this.state.openDeleteDialog}
      deleteRowData={this.state.rowData}
      handleClose={() => this.handleModalClose()}
      handleDelete={(rowData) => this.handleDeleteReclutamiento(rowData)}
    />

    </div>
    )
  }


};

export default withRouter(withStyles(styles)(NewReclutamientoCantoli));
