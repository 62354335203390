/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import DescriptionIcon from '@material-ui/icons/Description';
import Person from "@material-ui/icons/Person";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import AccountBox from "@material-ui/icons/AccountBox";
import AdjustIcon from '@material-ui/icons/Adjust';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import HomeWork from '@material-ui/icons/HomeWork';
import Home from '@material-ui/icons/Home';
import DragHandle from '@material-ui/icons/DragHandle';
import BrandingWatermark from '@material-ui/icons/BrandingWatermark';
import Straighten from '@material-ui/icons/Straighten';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Apps from '@material-ui/icons/Apps';
import OpenWith from '@material-ui/icons/OpenWith';
import Menu from '@material-ui/icons/Menu';
import Pause from '@material-ui/icons/Pause';
import TimelineIcon from '@material-ui/icons/Timeline';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BallotIcon from '@material-ui/icons/Ballot';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import WorkIcon from '@material-ui/icons/Work';
import PublicIcon from '@material-ui/icons/Public';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Explore from "@material-ui/icons/Explore";
import BusinessIcon from '@material-ui/icons/Business';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddIcon from '@material-ui/icons/Add';
import ListAltIcon from '@material-ui/icons/ListAlt';

import DashboardPage from "views/Dashboard/Dashboard.js";
import Users from "views/Users/Users.js";
import Clientes from "views/Clientes/Clientes.js";
import TiposUsuarios from "views/Users/TiposUsuarios.js";
import Configuracion from "views/Configuracion/Configuracion";
import Psicotecnicos from "views/Psicotecnicos/Psicotecnicos";
import NewPsicotecnicos from "views/Psicotecnicos/components/NewPsicotecnico";
import Reclutamiento from "views/Reclutamiento/Reclutamiento";
import ReclutamientoDetalle from "views/Reclutamiento/ReclutamientoDetalle";
import NewReclutamiento from "views/Reclutamiento/components/NewReclutamiento";
import NewReclutamientoCantoli from "views/Reclutamiento/components/NewReclutamientoCantoli";
import Servicios from "views/Servicios/servicios";

import Auditoria from "views/Auditoria/Auditoria";
import ReclutamientoCantoli from "views/Reclutamiento/ReclutamientoCantoli";
import Search from "@material-ui/icons/Search";
import Face from "@material-ui/icons/Face";
import GroupAdd from "@material-ui/icons/GroupAdd";
import Admin from "layouts/Admin";


const dashboardRoutes = [
  {
    accesos: [800],
    path: "/servicios",
    name: "Inicio",
    rtlName: "Ini",
    icon: Home,
    component: Servicios,
    layout: "/admin"
  },

  {
    accesos: [110],
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "DS",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },

  {
    show: false,
    accesos: [11, 12],
    groupComponent: true,
    name: 'Usuarios',
    open: 'open20',
    icon: AssignmentInd,
    dependences: [
      {
        show: false,
        accesos: [11],
        path: "/usuarios",
        name: "Usuarios",
        rtlName: "Us",
        icon: AssignmentInd,
        component: Users,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [12],
        path: "/tiposusuarios",
        name: "Tipos Usuarios",
        rtlName: "Us",
        icon: AccountBox,
        component: TiposUsuarios,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [400, 401, 402, 403, 404],
    groupComponent: true,
    name: 'Búsqueda y Selección',
    open: 'open400',
    icon: Search,
    dependences: [
      {
        show: false,
        accesos: [400],
        path: "/reclutamientolistado",
        name: "Listado",
        rtlName: "RecLis",
        icon: ListAltIcon,
        component: Reclutamiento,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [404],
        path: "/cantolireclutamientolistado",
        name: "Listado Cantoli",
        rtlName: "RecLis",
        icon: ListAltIcon,
        component: ReclutamientoCantoli,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [402],
        path: "/reclutamientodetalle",
        name: "Detalle",
        rtlName: "RecDet",
        icon: ListAltIcon,
        component: ReclutamientoDetalle,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [401],
        path: "/reclutamientonew",
        name: "Nueva Solicitud",
        rtlName: "PsiNew",
        icon: AddIcon,
        component: NewReclutamiento,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [403],
        path: "/cantolireclutamientonew",
        name: "Nueva Solicitud Cantoli",
        rtlName: "CaPsiNew",
        icon: AddIcon,
        component: NewReclutamientoCantoli,
        layout: "/admin"
      }
    ]
  },


  {
    show: false,
    accesos: [300, 301],
    groupComponent: true,
    name: 'Evaluaciones Psicológicas',
    open: 'open300',
    icon: Face,
    dependences: [
      {
        show: false,
        accesos: [300],
        path: "/psicotecnicoslistado",
        name: "Listado",
        rtlName: "PsiLis",
        icon: ListAltIcon,
        component: Psicotecnicos,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [301],
        path: "/psicotecnicosnew",
        name: "Nueva Solicitud",
        rtlName: "PsiNew",
        icon: AddIcon,
        component: NewPsicotecnicos,
        layout: "/admin"
      }
    ]
  },
  {
    show: false,
    accesos: [600],
    path: "#",
    name: "Desarrollo",
    rtlName: "PsiLis",
    icon: GroupAdd,
    //component: Psicotecnicos,
    layout: "/admin"
  },
  {
    show: false,
    accesos: [700],
    path: "#",
    name: "Consultoría",
    rtlName: "PsiLis",
    icon: WorkIcon,
    //component: Psicotecnicos,
    layout: "/admin"
  },
  {
    show: false,
    accesos: [130],
    groupComponent: true,
    name: 'Clientes',
    open: 'open201',
    icon: Person,
    dependences: [
      {
        show: false,
        accesos: [130],
        path: "/clientes",
        name: "Clientes",
        rtlName: "Cl",
        icon: Person,
        component: Clientes,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [500],
    groupComponent: true,
    name: 'Auditoria',
    open: 'open500',
    icon: ListAltIcon,
    dependences: [
      {
        show: false,
        accesos: [500],
        path: "/auditoriamailing",
        name: "Mailing",
        rtlName: "AudMai",
        icon: ListAltIcon,
        component: Auditoria,
        layout: "/admin"
      }
    ]
  },


  {
    show: false,
    accesos: [1],
    path: "/configuracion",
    name: "Configuracion",
    rtlName: "Confi",
    icon: SettingsIcon,
    component: Configuracion,
    layout: "/admin"
  },

];


export default dashboardRoutes
