import * as actionTypes from '../actions/actionTypes';


const initialState = {
   alertasVencimiento:{},
   alertasUmbral:{}
};

// TODO: Borrar?
// const reloadTable = (state) => { 
//     let state_copy = {...state}; 
//     state_copy.reload = !state_copy.reload;
//     return {...state_copy}
// }

const setUmbral = (state, action) => { 

    let umbral_copy = { ...action.data }
    return {...state, alertasUmbral:umbral_copy}
}

const setVencimiento = (state, action) => { 
    let vencimiento_copy = { ...action.data }
    return {...state, alertasVencimiento:vencimiento_copy}
}



const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.ALERTAS_SET_UMBRAL:
            return setUmbral(state, action);

        case actionTypes.ALERTAS_SET_VENCIMIENTO:
            return setVencimiento(state, action);
            
 
        
        default: 
            return state
    }
}
  

export default reducer;