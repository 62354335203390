export const StateListAuditoria = {
    auditoria: [],
    offset:0,
    checked: [],
    menuContext: null,
    botonesAcciones: {
        nuevo: {

            enabled: true,
            texto: 'Nuevo'
        },
        editar: {

            enabled: false,
            texto: 'Editar'
        },
        delete: {

            enabled: false,
            texto: 'Eliminar'
        }
    },
    modalOpen: false,
    openDeleteDialog:false,
    deleteRowData:null,
    isLoading:false




}

export const ColumnsListado = [
{ title: "Tipo", field: "subtipo" },
{ title: "Fecha", field: "fecha_mostrar" },
{ title: "ID", field: "id_issue" },
{ title: "Key", field: "key_issue" },
{ title: "Etapa", field: "status_issue" },
{ title: "Mensaje", field: "mensaje" }

];
